/**
 * Names of messages that are transmitted to or received from child or parent windows via
 * post message. See ChannelBridge.
 */
export class PostMessages {}

PostMessages.CONNECT_FAILED = 'CONNECT_FAILED';
PostMessages.READY = 'READY';
PostMessages.QUIT = 'QUIT';
PostMessages.OCCUPANTS = 'OCCUPANTS';
PostMessages.OCCUPANT_ADDED = 'OCCUPANT_ADDED';
PostMessages.OCCUPANT_REMOVED = 'OCCUPANT_REMOVED';
PostMessages.SET_OWN_ATTRIBUTE = 'SET_OWN_ATTRIBUTE';
PostMessages.SET_OCCUPANT_ATTRIBUTE = 'SET_OCCUPANT_ATTRIBUTE';
PostMessages.SET_ROOM_ATTRIBUTE = 'SET_ROOM_ATTRIBUTE';
PostMessages.ROOM_ATTRIBUTE_UPDATED = 'ROOM_ATTRIBUTE_UPDATED';
PostMessages.OCCUPANT_ATTRIBUTE_UPDATED = 'OCCUPANT_ATTRIBUTE_UPDATED';
PostMessages.NUM_SPECTATORS_UPDATED = 'NUM_SPECTATORS_UPDATED';
PostMessages.DATA_SETTINGS = 'DATA_SETTINGS';
