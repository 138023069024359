/**
 * Simple data structure defining the location of a MRTS server.
 */
import {notEmpty} from '../mputils/utils.js';

export class MRTSEndpoint {
  constructor (host, ports) {
    this.host = host;
    this.ports = ports;
  }

  /**
   * Indicates whether this MRTSEndpoint contains a valid MRTS server location.
   *
   * @returns {*}
   */
  isValid () {
    // If the host server is not empty and there's at least one port, consider this MRTSEndpoint valid.
    return notEmpty(this.host) && notEmpty(this.ports);
  }
}
