import {
  hexToCSSRgba,
  isArrayLike,
  isNil,
  notNil,
  notEmpty, isEmpty, getIfNotEmpty
} from './utils.js';
import {getMatchedCSSRules} from "./getMatchedCSSRules.js";

/**
 * Adds a <style> element, with the specified name, to the document.
 * @param name The name of the stylesheet; the value of this parameter is assigned to the
 *   <style> element's 'data-name' attribute.
 * @returns The newly created <style> element. To access that element's CSSStyleSheet object,
 *   use the .sheet property, as in: addStyleSheet('main').sheet.
 */
export function addStyleSheet (name) {
  let sheet = getStyleSheet(name);
  if (isNil(sheet)) {
    sheet = el('style');
    sheet.type = 'text/css';
    if (notNil(name)) {
      sheet.setAttribute('data-name', name);
    }
    document.head.append(sheet);
  }
  return sheet;
}

/**
 * Returns the stylesheet in the document whose <style> element's data-name attribute value matches
 * the supplied 'name' parameter.
 *
 * For example, given:
 *
 * <link href="App.css" rel="stylesheet" type="text/css" data-name="main">
 * <link href="Component.css" rel="stylesheet" type="text/css" data-name="component">
 *
 * @param name
 * @returns {StyleSheet}
 */
export function getStyleSheet (name) {
  for (let i=0; i<document.styleSheets.length; i++) {
    let sheet = document.styleSheets[i];
    if (sheet.ownerNode.getAttribute('data-name') === name) {
      return sheet;
    }
  }
}

/**
 * Returns the rule that matches selectorOrName from the specified stylesheet.
 * To retrieve regular style rules, supply the complete selector text for selectorOrName.
 * To retrieve regular @keyframes rules, supply the name after the @keyframes keyword (e.g., fade-in).
 *
 * To modify a style in the returned rule, use the rule's style property, as in:
 * theRule.style.display = 'block'
 *
 * To remove a style from the returned rule, use theRule.style.removeProperty('display').
 */
export function getCSSRule (stylesheet, selectorOrName) {
  let rules = getIfNotEmpty(stylesheet, 'cssRules');

  if (notNil(rules)) {
    return [...rules].find(rule => {
      if (rule.type === CSSRule.STYLE_RULE && rule.selectorText === selectorOrName) {
        return rule;
      } else if (rule.type === CSSRule.KEYFRAMES_RULE && rule.name === selectorOrName) {
        return rule;
      }
    });
  } else {
    return null;
  }
}

/**
 * Adds a new rule to the specified stylesheet. Example:
 *   setCSSRule('main',
 *              '.panel',
 *              `color: #CCCCCC;
 *               margin: 5px;
 *              `
 *             );
 *
 * @param stylesheet A CSSStyleSheet object or the name of a stylesheet in which to set the rule.
 * @param selectorText The full string text of the rule, including selector and properties.
 * @param properties A list of CSS properties (e.g, 'margin: 5px');
 *
 */
export function setCSSRule (stylesheet, selectorText, properties) {
  let sheet;
  if (stylesheet instanceof CSSStyleSheet) {
    sheet = stylesheet;
  } else if (typeof stylesheet === 'string') {
    sheet = getStyleSheet(stylesheet);
    if (isNil(sheet)) {
      // The .sheet property is the desired CSSStyleSheet object
      sheet = addStyleSheet(stylesheet).sheet;
    }
  } else {
    throw new Error('The stylesheet parameter must be a CSSStyleSheet or String name of a stylesheet.');
  }

  let rule = getCSSRule(sheet, selectorText);
  // Brute force: replace the entire rule with the newly supplied rule. Consumers wishing to update
  // only a subset of properties for a given rule should use getCSSRule().
  if (rule) {
    deleteCSSRules([sheet], [selectorText]);
  }
  sheet.insertRule(`${selectorText} { 
      ${properties} 
    }`);
}

export function deleteCSSRules (styleSheets, ruleSelectorTextList) {
  for (let i = 0; i < styleSheets.length; i++) {
    let sheet = styleSheets[i];

    for (let j = sheet.cssRules.length; --j >= 0;) {
      let rule = sheet.cssRules[j];
      for (let k = ruleSelectorTextList.length; --k >= 0;) {
        if (rule.selectorText === ruleSelectorTextList[k]) {
          // log(`Deleting CSS rule: ${ruleSelectorTextList[k]} ${sheet.cssRules[j].style.cssText}`);
          sheet.deleteRule(j);
        }
      }
    }
  }
}

export function removeClassByPrefix (elem, prefix) {
  var reg = new RegExp("\\b"+prefix+"\\S+", "g");
  elem.className = elem.className.replace(reg,' ');
}

export function getFontFamilies (stylesheet) {
  let families = new Set();
  for (let i = stylesheet.cssRules.length; --i >= 0;) {
    let rule = stylesheet.cssRules[i];
    if (rule.type === CSSRule.FONT_FACE_RULE) {
      // IMPORTANT COMPATIBILITY NOTE:
      // IN FIREFOX, rule.style.fontFamily returns undefined, so this code MUST use getPropertyValue()
      // to access the value of the "font-family" CSS style property
      families.add(rule.style.getPropertyValue('font-family'));
    }
  }
  return families;
}

export function setWidthToHeight (elements) {
  // If a single element is passed, wrap it in an array
  if (!isArrayLike(elements)) {
    elements = [elements];
  }

  elements.forEach((elem) => {
    if (getComputedStyle(elem).width !== getComputedStyle(elem).height) {
      // Intentionally assign height to width (this is not an error)
      elem.style.width = getComputedStyle(elem).height;
    }
  });
}

export function processDescendents (node, func, args, type) {
  if (node == null) {
    return;
  }
  if (type === undefined || node.nodeType === type) {
    if (args && args.length > 0) {
      func(node, ...args);
    } else {
      func(node);
    }
  }
  node = node.firstChild;
  while (node) {
    processDescendents(node, func, args, type);
    node = node.nextSibling;
  }
}

export function queryByAttribute (elem, name, value) {
  return elem.querySelector('[' + name + '=' + value + ']');
}

export function queryAllByAttribute (elem, name, value) {
  return elem.querySelectorAll('[' + name + '=' + value + ']');
}

export function div (id, className) {
  return el('div', id, className);
}

export function iframe (id) {
  return el('iframe', id);
}

export function el (tagName, id, className) {
  let el = document.createElement(tagName);
  if (notNil(id)) el.setAttribute('id', id);
  if (notNil(className)) {
    el.classList.add(className)
  }
  return el;
}

export function attributeToID (attributeValue) {
  return attributeValue.replace(/_/g,"");
}

export function idToAttribute (id) {
  return `_${id}`;
}

/**
 * Returns a promise that is fulfilled when ALL <img> elements in the specified element
 * have either loaded, failed to load, or never attempted to load due to an empty or missing src
 * attribute.
 *
 * @param elem A single <img> element or a container element with nested <img> descendents
 */
export function awaitImgs (elem) {
  return new Promise((resolve) => {
    let imgs;
    if (elem instanceof HTMLImageElement) {
      imgs = [elem];
    } else {
      imgs = Array.from(elem.querySelectorAll('img'));
    }
    let numImgs = imgs.length;
    let numImgsResolved = 0;
    let numImgsFailed = 0;

    let handleImgLoaded = (img) => {
      numImgsResolved++;
      checkForDone();
    };
    let handleImgFailed = (img) => {
      numImgsResolved++;
      numImgsFailed++;
      checkForDone();
    };
    let checkForDone = () => {
      if (numImgsResolved === numImgs) {
        // All images have either loaded or failed, so fulfil the Promise
        resolve(numImgsFailed);
      }
    };

    // Examine each image to determine whether it is already complete. If an given image isn't
    // already complete, wait for its onload or onerror events to be dispatched.
    imgs.forEach((img) => {
      let imgIsEmpty = !img.hasAttribute('src') || img.getAttribute('src') === '';
      if (imgIsEmpty) {
        // This img element has no src attribute OR src is set to the empty string. This is an
        // edge case that should be avoided. We treat such img elements as resolved.
        handleImgLoaded(img);
      } else if (img.complete) {
        // This image has finished loading
        if (img.naturalWidth > 0) {
          // We treat complete images with greater-than-zero width as valid and resolved
          handleImgLoaded(img);
        } else {
          // We treat complete images with 0 width as invalid, but resolved
          handleImgFailed(img);
        }
      } else {
        // This image hasn't finished loading yet, so handle load and
        // error cases with event listeners
        let loadListener = (e) => {
          e.target.removeEventListener('load', loadListener);
          handleImgLoaded(e.target);
        };
        img.addEventListener('load', loadListener);

        let errorListener = (e) => {
          e.target.removeEventListener('error', errorListener);
          handleImgFailed(e.target);
        };
        img.addEventListener('error', errorListener);
      }
    });
  });
}

/**
 * Hide the specified element or elements. Note that visibility is managed by setting the CSS
 * styles "visibility" and "position" at the element level. To prevent hide() and show() from
 * overwriting style values, element-level styles for "visibility" and "position" should never
 * be set via code OTHER than show() and hide(). Instead, thoses properties shoudl be managed
 * via CSS classes, which are unaffected by direct assignment to element-level styles.
 *
 * @param elem The element to hide (if selector is not provided), or the element at which to start
 *  searching for elements to hide (based on the provided selector).
 * @param selector A CSS selector specifying the list of elements to hide.
 */
export function hide (elem, selector) {
  if (isNil(elem)) {
    return;
  }

  let nodes;
  if (isNil(selector)) {
    nodes = [elem];
  } else {
    nodes = elem.querySelectorAll(selector);
  }

  nodes.forEach(node => {
    node.style.display = 'none';
  });
}

/**
 * Show the specified element (if selector is not provided) or elements (as specified by the
 * provided selector).
 */
export function show (elem, selector) {
  if (isNil(elem)) {
    return;
  }

  let nodes;
  if (isNil(selector)) {
    nodes = [elem];
  } else {
    nodes = elem.querySelectorAll(selector);
  }

  nodes.forEach(node => {
    node.style.removeProperty('display');
  });
}

export function hideAllChildren (elem) {
  if (notNil(elem)) {
    Array.from(elem.children).forEach(child => {
      hide(child);
    });
  }
}

/**
 * Alphabetizes the children of the specified container element. (Note: this function could be
 * replaced by a more feature-rich library such as https://github.com/Sjeiti/TinySort.)
 *
 * Example 1: Children sorted by simple text content
 *
 * <div id='employees'>
 *  <div class='employee'>Han</div>
 *  <div class='employee'>Dieter</div>
 *  <div class='employee'>Lisa</div>
 * <div>
 *
 * // Sort by name
 * sortByTextContent(document.body.querySelector('#employees'), '.employee');
 *
 * Example 2: Children sorted by a nested element's text content
 *
 * <div id='employees'>
 * <div class='employee'><div class='firstname'>Han</div><div class='lastname'>Wong</div></div>
 * <div class='employee'><div class='firstname'>Dieter</div><div class='lastname'>Schwartz</div></div>
 * <div class='employee'><div class='firstname'>Lisa</div><div class='lastname'>Jones</div></div>
 * <div>
 *
 * // Sort by last name
 * sortByTextContent(document.body.querySelector('#employees'), '.employee', '.lastname');
 *
 * @param container The parent of the elements to be sorted.
 * @param childSelector The selector identifying the elements to be sorted.
 * @param textContainerSelector Optional selector identifying the element that contains the text on
 *   which to base the sort.
 */
export function sortByTextContent (container, childSelector, textContainerSelector) {
  let elementsArray = Array.from(container.querySelectorAll(childSelector));
  let originalElementsArray = elementsArray.slice(0);  // Copy the original order

  elementsArray.sort((elementA, elementB) => {
    let stringA, stringB;
    if (textContainerSelector) {
      stringA = elementA.querySelector(textContainerSelector).textContent.trim();
      stringB = elementB.querySelector(textContainerSelector).textContent.trim();
    } else {
      stringA = elementA.textContent.trim();
      stringB = elementB.textContent.trim();
    }
    if (stringA < stringB) return -1;
    if (stringA > stringB) return 1;
    return 0;
  }).forEach((element, index) => {
    // If the element hasn't changed position, don't re-append it. This helps reduce flicker when
    // elements are sorted, but is not an exhaustive performance optimization.
    if (originalElementsArray[index] !== element) {
      element.parentElement.appendChild(element);
    }
  });
}

export function removeAllChildren (elem) {
  while (elem.firstChild) {
    elem.removeChild(elem.firstChild);
  }
}

/**
 * Copies the width, height, left, and top of the "from" element to the "to" element.
 */
export function matchSizeAndPosition (from, to) {
  to.style.width = getComputedStyle(from).width;
  to.style.height = getComputedStyle(from).height;
  to.style.left = getComputedStyle(from).left;
  to.style.top = getComputedStyle(from).top;
}

export function configureVideoElementForAutoplay (videoElement) {
  // Required to allow videos to play on mobile and newer versions of desktop browsers
  videoElement.setAttribute('playsinline', '');
  videoElement.setAttribute('muted', '');
  videoElement.setAttribute('autoplay', '');
  videoElement.muted = true;
}

/**
 * For all descendents specified by 'selector 'of the specified 'container', assigns a minimum width
 * that will fit the specified text. Requires that each node's font is known (i.e., can be retrieved
 * via getComputedStyle()) at execution time. To ensure that a newly created element's font is known,
 * use onAdded() before calling fitMinWidthToText().
 */
export function fitMinWidthToText (container, selector, text) {
  let elems = container.querySelectorAll(selector);
  elems.forEach(elem => {
    elem.style.minWidth = getTextWidth(text, elem) + 'px';
  });
}

/**
 * Assigns the specified text content to the first element in elementList that is not null.
 * Used to choose a backup text field depending on theme structure. For example, some themes
 * use a generic title_txt for both question and main scoreboard, while other themes use
 * separate question_txt and main_scoreboard_txt.
 *
 * Example:
 *     this.setText([this.main.querySelector('#main_scoreboard_title_txt'),
 *       this.main.querySelector('#main_title_txt')],
 *       scene.content.question.text);
 *
 * @param elementList A list of text fields through which to cascade.
 * @param text The text to assign to .textContent.
 */
export function setText (elementList, text) {
  if (notNil(elementList)) {
    elementList.forEach(element => {
      if (notNil(element)) {
        element.textContent = text;
      }
    });
  }
}

/**
 * Returns the width, in integer pixels, of the specified text using either a) the specified node's
 * current font and font styles (as reported by getComputedStyle().font), or b) the specified font.
 *
 * @param text The text to measure.
 * @param node The element in which the text appears.
 * @param font The optional information for the font in which the text will be rendered. Must follow
 *   the format of getComputedStyle().font. However, to work in all browsers, the font value should
 *   be retrieved via getFontFromComputedStyle(), which polyfills around a Firefox bug. This
 *   parameter is provided for high performance situations, where repeatedly invoking getComputedStyle()
 *   would be undesirable.
 */
export function getTextWidth (text, node, font) {
  let canvas = getTextWidth.canvas;
  if (isNil(getTextWidth.canvas)) {
    canvas = getTextWidth.canvas = document.createElement('canvas');
  }
  font = isNil(font) ? getFontFromComputedStyle(getComputedStyle(node)) : font;
  let context = canvas.getContext('2d');
  context.font = font;
  var metrics = context.measureText(text);
  return Math.round(metrics.width);
}

/**
 * Returns a valid .font property value for the specified computedStyle. This function is a polyfill
 * for Firefox, which returns the empty string for getComputedStyle(elem).font.
 */
function getFontFromComputedStyle (computedStyle) {
  let font = computedStyle.font;
  // Firefox returns the empty string for .font, so create the .font property manually
  if (font === '') {
    // Firefox uses percentages for font-stretch, but Canvas does not accept percentages
    // so convert to keywords, as listed at:
    //   https://developer.mozilla.org/en-US/docs/Web/CSS/font-stretch
    let fontStretchLookupTable = {
      '50%': 'ultra-condensed',
      '62.5%': 'extra-condensed',
      '75%': 'condensed',
      '87.5%': 'semi-condensed',
      '100%': 'normal',
      '112.5%': 'semi-expanded',
      '125%': 'expanded',
      '150%': 'extra-expanded',
      '200%': 'ultra-expanded'
    };
    // If the retrieved font-stretch percentage isn't found in the lookup table, use
    // 'normal' as a last resort.
    let fontStretch = fontStretchLookupTable.hasOwnProperty(computedStyle.fontStretch)
      ? fontStretchLookupTable[computedStyle.fontStretch]
      : 'normal';
    font = computedStyle.fontStyle
      + ' ' + computedStyle.fontVariant
      + ' ' + computedStyle.fontWeight
      + ' ' + fontStretch
      + ' ' + computedStyle.fontSize
      + '/' + computedStyle.lineHeight
      + ' ' + computedStyle.fontFamily;
  }
  return font;
}

export function rewindAndPause (video) {
  video.currentTime = 0;
  video.pause();
}

export function rewindAndPlay (video) {
  video.currentTime = 0;
  video.play();
}

export function setRuleBackgroundColor (sheetName, selectorText, color, opacity) {
  let rule = getCSSRule(getStyleSheet(sheetName), selectorText);
  if (notNil(color)) {
    if (color.startsWith('#')) {
      // The supplied color starts with "#", so treat it as a hex color value and combine it with opacity.
      rule.style.background = hexToCSSRgba(color, opacity);
    } else {
      // The supplied color doesn't start with "#", so pass it directly through to the CSS background property.
      rule.style.background = color;
    }
  } else {
    rule.style.removeProperty('background');
  }
}

/**
 * Assigns the specified CSS rule's property the specified value.
 */
export function setRuleProperty (sheetName, selectorText, propName, propValue) {
  let rule = getCSSRule(getStyleSheet(sheetName), selectorText);
  if (notNil(rule)) {
    rule.style[propName] = propValue
  }
}


/**
 * Returns a promise that resolves when the browser has finished appending the specified childNode to the
 * specified parentNode, but BEFORE the browser has rendered the childNode. For newly created nodes that
 * have not yet been added to the DOM, the onAdded() function provides safe access to information about
 * childNode just before childNode renders. Specifically, when onAdded resolves():
 *
 * 1) The childNode's computed font can safely be retrieved via 'getComputedStyle(childNode).font'
 * 2) If parentNode is in the DOM, the childNode's offsetWidth/offsetHeight can safely be retrieved
 *
 * The onAdded() function can be used anywhere a node's dimensions must be measured before that node is displayed.
 * For example, scoreboards use onAdded() to measure the width of the user's "Rank" text in its assigned font.
 * The measured width can then be used to size the width of the "Rank" column before it appears on screen.
 *
 * Note that in the interest of performance, onAdded() is intended for use with direct children only,
 * not descendents.
 */
export function onAdded (childNode, parentNode) {
  return new Promise((resolve, reject) => {
    if (isNil(childNode) || isNil(parentNode)) {
      reject();
    } else if (parentNode.contains(childNode)) {
      resolve();
    } else {
      let observer = new MutationObserver(mutations => {
        if (parentNode.contains(childNode)) {
          observer.disconnect();
          resolve();
        }
      });

      observer.observe(parentNode, {
        attributes: false,
        childList: true,
        characterData: false,
        subtree: false
      });
    }
  });
}

/**
 * Returns the most-specific CSS line-height value assigned to the node. This is the value as set
 * in CSS, such as 1.2 or 0.9, NOT the computed value that would be returned by getComputedStyle().
 *
 * @param node
 * @returns A CSS line-height value, such as 1.2 or 0.9.
 */
export function getCSSLineHeight (node) {
  let matchedRules = Array.from(getMatchedCSSRules(node));
  let lineHeight = null;
  let thisLineHeight = null;

  // The higher the array index, the higher the CSS-rule specificity
  if (notEmpty(matchedRules)) {
    matchedRules.forEach(rule => {
      thisLineHeight = rule.style.getPropertyValue('line-height');
      if (isEmpty(thisLineHeight)) {
        // Skip.
        // For the sake of performance, use isEmpty() not notEmpty() here
      } else {
        lineHeight = thisLineHeight;
      }
    });
  }
  return lineHeight;
}

/**
 * Returns the text line-height for the specified node, as follows:
 * 1) If the line height IS set via CSS, returns that value (e.g., 1.2)
 * 2) If the line height is NOT set via CSS, returns the calculated line-height based on getComputedStyle()
 * 3) If the line height cannot be calculated (e.g., on Chrome), returns 1.2, the typical default
 *    set by web browsers.
 *
 * For accurate results in Chrome, specify an explicit CSS line-height.
 *
 * @param node The element whose line-height will be returned.
 * @param computedStyle The value of getComputedStyle() for the specified node. This parameter is optional
 *   and provided for the sake of performance only. If not supplied, computedStyle will be retrieved by
 *   getLineHeight().
 */
export function getLineHeight (node, computedStyle) {
  let lineHeight = getCSSLineHeight(node);

  // If no CSS line-height is available...
  if (isEmpty(lineHeight)) {
    // ...calculate approximate line-height using computedStyle values.
    if (isNil(computedStyle)) {
      computedStyle = getComputedStyle(node);
    }
    lineHeight = parseInt(computedStyle.lineHeight)/parseInt(computedStyle.fontSize);
    lineHeight = Math.round( lineHeight * 10 ) / 10;  // Round to one decimal place (e.g., 1.2)
    // If lineHeight is invalid, assume 1.2 (a typical default).
    lineHeight = isNaN(lineHeight) ? 1.2 : lineHeight;
  }
  return lineHeight;
}

/**
 * Invokes the specified callback whenever the specified target becomes visible (immediately before
 * that target appears on screen).
 *
 * "Becomes visible," in this case, means that the target is in the DOM, has been scrolled into view,
 * is NOT hidden by the style "visibility: hidden", and is NOT hidden by the style "display: none".
 * No other visibility factors are considered. Hence, even if callback() is invoked, the target
 * might not actually be visible to the human eye due to, say, an effect such as "opacity: 0" or
 * because the target is occluded by other content.
 *
 * If target is already visible, the specified callback is invoked immediately.
 *
 * @param target The element to be observed for visibility changes.
 * @param callback A function invoked when the target becomes visible.
 *
 * @returns {IntersectionObserver} An IntersectionObserver instance, which can be used to stop
 *   observing visibility changes for the target (via unobserve()).
 */
export function onVisible (target, callback) {
  let observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        callback(entry);
      }
    });
  });
  observer.observe(target);
  return observer;
}

export function removeCSSPropertiesFromRules (stylesheet, ruleSelectors, propertyNames) {
  ruleSelectors.forEach(ruleSelector => {
    let rule = getCSSRule(stylesheet, ruleSelector);
    if (notNil(rule)) {
      propertyNames.forEach(propertyName => {
        rule.style.removeProperty(propertyName);
      });
    }
  });
}

/**
 * Reads individual font property values from the specified fontValue string, replaces the font size,
 * and returns a new font-property string with the updated font size value. The fontValue argument
 * must use the same syntax as a CSS font specifier, which can be retrieved for any element using
 * MegaScreen's getFontFromComputedStyle() function. For font-specifier syntax, see:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font
 *
 * The replaceComputedStyleFontSize() function is used by fitText() to improve font-resizing
 * performance. By using replaceComputedStyleFontSize(), fitText() avoids calling the more
 * expensive getComputedStyle() during each font-size test.
 *
 * @param fontValue A CSS font specifier value. Example font-specifier values follow.
 *    Example fontValue from Safari:
 *      normal normal normal normal 32px/42px proximaCondensedSemiBold
 *
 *    Example fontValue from Chrome up to v78 (notice spaces around the "/"):
 *      normal normal 400 normal 47px / 42.3px proximaCondensedSemiBold
 *
 *    Example fontValue from Chrome v79 and newer:
 *      47px proximaCondensedSemiBold
 *
 *    Example fontValue from Firefox, manually generated by getFontFromComputedStyle()
 *      normal normal 400 normal 47px/42.3px proximaCondensedSemiBold
 *
 * @param newFontSize An integer font size (without "px")
 */
function replaceComputedStyleFontSize (fontValue, newFontSize) {
  let parsedFont = parseFontProperty(fontValue);
  let newFontValue = parsedFont.fontStyle
    + ' ' + parsedFont.fontVariant
    + ' ' + parsedFont.fontWeight
    + ' ' + newFontSize + 'px'
    + (parsedFont.lineHeight !== 'normal' ? '/' + parsedFont.lineHeight : '')
    + ' ' + parsedFont.fontFamily;

  return newFontValue;
}

/**
 * Parses a CSS font property string and returns the individual font values as the properties of an
 * object. This function is used by replaceComputedStyleFontSize(), which exists for the sake of
 * performance only.
 */
function parseFontProperty (font) {
  let fontFamily = null,
    fontSize = null,
    fontStyle = 'normal',
    fontWeight = 'normal',
    fontVariant = 'normal',
    lineHeight = 'normal';

  let parts = font.split(/\s+/);
  let part;
  outer: while (part = parts.shift()) {
    switch (part) {
      case 'normal':
        break;

      case 'italic':
      case 'oblique':
        fontStyle = part;
        break;

      case 'small-caps':
        fontVariant = part;
        break;

      case 'bold':
      case 'bolder':
      case 'lighter':
      case '100':
      case '200':
      case '300':
      case '400':
      case '500':
      case '600':
      case '700':
      case '800':
      case '900':
        fontWeight = part;
        break;

      default:
        if (!fontSize) {
          let fontParts = part.split('/');
          fontSize = fontParts[0];
          if (fontParts.length > 1) {
            lineHeight = fontParts[1];
          }
          break;
        }

        fontFamily = part;
        if (part.length) {
          fontFamily += ' ' + parts.join(' ');
        }
        break outer;
    }
  }

  return {
    'fontStyle': fontStyle,
    'fontVariant': fontVariant,
    'fontWeight': fontWeight,
    'fontSize': fontSize,
    'lineHeight': lineHeight,
    'fontFamily': fontFamily
  };
}

/**
 * Shrinks the font in the specified node to fit within the specified width and height boundaries.
 *
 * @param node The element whose text will be resized.
 * @param width The maximum width allowed for text before it is resized.
 * @param height The maximum width allowed for text before it is resized.
 * @param lineHeight The lineHeight of the text in th element. If not supplied, fitText() retrieves
 *      it automatically, but retrieval is time-consuming, so where performance is a concern, the
 *      lineHeight should be stored externally and provided to fitText().
 * @param optimizeSpeedOverAccuracy If true, with each pass in fitText()'s search for a font size that
 *      fits, fitText() will more aggressively reduce the font size. This results in fewer size checks,
 *      and, therefore, a faster match. If optimizeSpeedOverAccuracy is false, fitText() will check
 *      every single font size between the original and the minimum allowed, which takes longer, but
 *      produces a size that results in the text more accurately filling the available space.
 */
export function fitText (node, width, height, lineHeight, optimizeSpeedOverAccuracy) {
  if (width === 0 || height === 0) {
    // Skip all calculations if width or height are 0 because the node won't be visible
    return;
  }

  // Pre-calculate as many textFits() parameters as possible to avoid
  // recalculating them with every textFits() call
  let computedStyle = getComputedStyle(node);
  let font = getFontFromComputedStyle(computedStyle);
  let originalFontSize = parseInt(computedStyle.fontSize);
  let targetFontSize = originalFontSize;
  // TODO: Externalize to either theme setting or deployment setting
  let minFontSize = 8;

  // If the text's line height is not supplied...
  if (isNil(lineHeight)) {
    // ...retrieve the highest specificity line-height applied via CSS
    lineHeight = getLineHeight(node, computedStyle);
  }
  let isUppercase = computedStyle.textTransform === 'uppercase';
  let n = isUppercase ? 'N' : 'n';
  let originalNWidth = getTextWidth(n, node, font);
  let availableWidth = width - parseInt(computedStyle.paddingLeft) - parseInt(computedStyle.paddingRight);
  let text = isUppercase ? node.textContent.toUpperCase() : node.textContent;
  // Elements without CSS "white-space: nowrap" are multiline as long as they contain at least one space.
  // If the text to be resized contains no spaces AT ALL, treat it as single-line content (i.e., identical
  // to 'nowrap'elements).
  let multiline = computedStyle.whiteSpace !== 'nowrap' && (text.indexOf(" ") !== -1);

  // TODO: Remove this debugging log message
  // announce(`\n\n## ============== START Fitting text. Original size: ${originalFontSize}. Text: ${text}`);

  // TODO: Very long term, monitor browser APIs for potentially new ways to detect text overflow. For example,
  //   an API such as the now-deprecated overflowchanged event might be reintroduced. See:
  //   https://stackoverflow.com/questions/48048193/what-is-the-replacement-for-overflowchanged-event-handler
  let foundFontSize = false;
  while (!foundFontSize) {
    if (targetFontSize > minFontSize) {
      let result = textFits(text,
        node,
        availableWidth,
        height,
        originalFontSize,
        targetFontSize,
        font,
        lineHeight,
        originalNWidth,
        multiline);
      if (result.success) {
        foundFontSize = true;
      } else {
        if (result.lineHeightRatio < 1) {
          // Font is too large to fit even a single character in the available vertical space
          targetFontSize *= result.lineHeightRatio;
        } else {
          if (optimizeSpeedOverAccuracy) {
            // Space fits one line of text vertically, but there are too many characters to fit horizontally
            if (result.textWidthRatio === Infinity) {
              targetFontSize *= .8;
            } else {
              // Below 20px font size, start checking every single size
              if (targetFontSize <= 20) {
                targetFontSize--;
              } else {
                targetFontSize *= .9;
              }
            }
          } else {
            targetFontSize--;
          }
        }

        font = replaceComputedStyleFontSize(font, targetFontSize);
      }
    } else {
      targetFontSize = minFontSize;
      foundFontSize = true;
    }
  }
  // TODO: Remove this debugging log message
  // announce(`## ============= DONE Fitting text. Final size: ${targetFontSize}. Text: ${text}`);
  if (originalFontSize !== targetFontSize) {
    node.style.fontSize = `${targetFontSize}px`;
  }
}

/**
 * Checks whether the specified text fits within the specified width/height boundaries at the
 * specified targetFontSize.
 */
function textFits (text, node, width, height, originalFontSize, targetFontSize,
                   font, lineHeight, originalNWidth, multiline = true) {
  let nWidth = originalNWidth * (targetFontSize/originalFontSize);

  // TODO: Monitor this character-width padding over time in various themes and fonts. Refine if necessary.
  // For larger font sizes, pad the estimated width of the letter 'n' by an arbitrary 3%, which reduces
  // the size of the final target font size. This is a magic value that helps prevent text from exceeding
  // the requested width/height boundaries.
  if (targetFontSize > 14) {
    nWidth *= 1.03;
  }

  // TODO: Monitor this word-wrap gap over time in various themes and fonts. Refine if necessary.
  // The following code accounts for the typical gap required at the end of a line when a word wrap occurs.
  // The gap required increases as the font size increases. Assume one "n"-character width as the base gap
  // size, then for every 10 pts of the font size, add another "n"-character width to the gap. For example,
  // for a 14 pt font, use 2n, for a 24pt font, use 3n, for a 34pt font, use 4n, and so on.
  // This implementation could be improved by attempting to calculate the locations of actual word wraps,
  // but given the many wrapping rules, fonts, and font styles (bold, italic, etc), the algorithm could
  // never perfectly reflect actual browser behavior, and may well be too slow for use in this
  // text-measurement algorithm. Sometimes a cable tie is as good as a weld...
  let wordWrapGapWidth = (1+Math.round(targetFontSize/10))*nWidth;

  // When considering available space, never fill more height than width (in rects that are taller than
  // they are wide, clamp height to width so the text fits into a square)
  height = Math.min(width, height);

  // TODO: Monitor this line-height padding over time in various themes and fonts. Refine if necessary.
  // Pad the estimated line height by an arbitrary 5%, which reduces the size of the final target font size.
  // This is a magic value that helps prevent text from exceeding the requested width/height boundaries.
  let approximatedLineHeight = (targetFontSize * lineHeight) * 1.05;
  let approximatedNumLinesAvailable = Math.floor(height / approximatedLineHeight);

  // The approximate horizontal space available for text on each line.
  let approximatedLineWidth;
  // The total horizontal space available for text across all lines.
  let totalWidthAvailable;
  if (multiline) {
    // When calculating the width-per-line available for text in multiline text fields, subtract the
    // wordWrapGapWidth from the approximate line width because the word-wrap gap area is not available
    // for text (see earlier comment, above wordWrapGapWidth definition).
    approximatedLineWidth = Math.max(0, width - wordWrapGapWidth);
    // Calculate total width available, which includes reinstating one "word-wrap gap" area. Reinstating one
    // "word-wrap gap" handles the case of the last line of a multiline text field. The last line doesn't wrap,
    // so it doesn't have a wrap gap.
    totalWidthAvailable = (approximatedLineWidth * approximatedNumLinesAvailable) + wordWrapGapWidth;

    // For narrow text fields with large fonts, the "wordWrapGapWidth" can end up being larger than the
    // actual amount of space available. Hence, if the approximated total width available plus the
    // single reinstated wordWrapGapWidth is larger than the width available WITHOUT wrap gaps, then
    // revoke the reinstated "last line" wrap gap. Without the following condition, totalWidthAvailable
    // might end up being too wide in narrow text fields with large fonts.
    let totalWidthAvailableWithoutWrapGap = width*approximatedNumLinesAvailable;
    if (totalWidthAvailable > totalWidthAvailableWithoutWrapGap) {
      totalWidthAvailable -= wordWrapGapWidth;
    }
  } else {
    // Single-line text field, so no wrap gap. If approximatedNumLinesAvailable is greater than one,
    // force num-lines of 1 (because this is a no-wrap text field). If approximatedNumLinesAvailable
    // is 0, use num-lines of 0 (the text is too tall to fit in the available height).
    totalWidthAvailable = Math.min(1, approximatedNumLinesAvailable) * width;
  }

  // Calculate actual total width of text (not including line breaks)
  let totalTextWidth = getTextWidth(text, node, font);

  // TODO: Remove this debugging log message
  // announce(`## [${node.id}] testing font size: ${targetFontSize}. Must fit within [w:${width}, h:${height}]. Approx line height: ${approximatedLineHeight}, lines available: ${approximatedNumLinesAvailable}, approximated nWidth: ${nWidth}, numChars: ${text.length} totalTextWidth: ${totalTextWidth}, available width: ${totalWidthAvailable}, approximate line width: ${approximatedLineWidth}`);
  let result = {
    "success": totalWidthAvailable >= totalTextWidth,
    "lineHeightRatio": height/approximatedLineHeight,
    "textWidthRatio": totalTextWidth / totalWidthAvailable
  }

  return result;
}

/**
 * A convenience wrapper around requestAnimationFrame() that invokes a callback immediately
 * before the browser next updates the screen. This function exists purely for code readability
 * because it is often used without any animation in places where the name "requestAnimationFrame"
 * would be unintuitive.
 *
 * Usage:
 * awaitRender().then(() => {
 *   // Code here executes immediately before the next screen update
 * });
 *
 */
export function awaitRender () {
  return new Promise (resolve => {
    requestAnimationFrame(() => {
      resolve();
    });
  });
}

/**
 * Returns the untransformed (x,y) position of the specified element relative to the viewport.
 *
 * @param elem The element whose global position will be returned.
 * @param globalScale A scale object, as returned by getScale(), that should be considered the
 *                    global scale relative to elem. Normally, the global scale is the scale of
 *                    the application root element. For example:
 *                    getGlobalPosition(someChild, getScale(document.querySelector('.applicationRoot'));
 *
 * @returns {{x: number, y: number}}
 */
export function getGlobalPosition (elem, globalScale) {
  let rect = {x:0,y:0};
  if (isNil(elem)) {
    return rect;
  }
  let elemBoundingRect = elem.getBoundingClientRect();

  rect.x = Math.round(elemBoundingRect.left * (1/globalScale.x));
  rect.y = Math.round(elemBoundingRect.top * (1/globalScale.y));
  return rect;
}

/**
 * Returns the untransformed (x,y) position of the specified element relative to the
 * element's parent.
 *
 * @param elem The element whose local position will be returned.
 * @param globalScale A scale object, as returned by getScale(), that should be considered the
 *                    global scale relative to elem. Normally, the global scale is the scale of
 *                    the application root element. For example:
 *                    getLocalPosition(someChild, getScale(document.querySelector('.applicationRoot'));
 *
 * @returns {{x: number, y: number}}
 */
export function getLocalPosition (elem, globalScale) {
  let rect = {x:0,y:0};
  if (isNil(elem)) {
    return rect;
  }
  let elemRect = getGlobalPosition(elem, globalScale);

  let parentRect = {left:0,top:0};
  if (notNil(elem.parentNode)) {
    parentRect = getGlobalPosition(elem.parentNode, globalScale);
  }
  rect.x = elemRect.x - parentRect.x;
  rect.y = elemRect.y - parentRect.y;

  return rect;
}

/**
 * Returns the x-scale and y-scale transformations currently applied to the specified element.
 *
 * @param elem
 * @returns {{x: number, y: number}}
 */
export function getScale (elem) {
  let scale = {x:1, y:1};
  if (isNil(elem)) {
    return scale;
  } else {
    let transform = getComputedStyle(elem).transform;
    if (transform !== 'none') {
      let transformArray = transform.replace('matrix(', '').split(',');
      scale.x = parseFloat(transformArray[0]);
      scale.y = parseFloat(transformArray[3]);
    }
    return scale;
  }
}

/**
 * Copies the specified text to the operating system's clipboard.
 *
 * @param text The string of text to copy.
 */
export function copyTextToClipboard (text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position="fixed";  //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    console.error('copy failed ' + err)
  }
  document.body.removeChild(textArea);
}

/**
 * Same as insertAdjacentElement, but allows for more than one element to be inserted
 * at the specified position.
 */
export function insertAdjacentElements (position, parent, ...elements) {
  if (notNil(parent) && notEmpty(elements)) {
    parent.insertAdjacentElement(position, elements[0]);

    for (let i = 0; i < elements.length-1; i++) {
      elements[i].insertAdjacentElement('afterend', elements[i+1]);
    }
  }
}

/**
 * Downloads the file at href, and optionally assigns it the specified fileName.
 *
 * @param href The URL to the file to download.
 * @param fileName Optional file name (e.g., 'info.pdf')
 */
export function downloadFile (href, fileName) {
  // Create anchor
  let a = el('a');
  a.style.display = 'none';
  document.body.append(a);
  a.href = href;
  fileName = isNil(fileName) ? '' : fileName;
  a.setAttribute("download", fileName);

  // Trigger download
  a.click();

  // Remove anchor
  document.body.removeChild(a);
}

/**
 * Sorts the child elements of the specified parent element based on the supplied compareFunc.
 * For compareFunc usage, see the documentation for Array's compareFunction parameter here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *
 * Example:
 * // Sorts someContainer's children by last-modified date, newest to oldest. Requires all child
 * // elements to have a custom attribute, "data-last-modified" with values specifying the date
 * // in Unix Time.
 * sortChildElements(someContainer, (a, b) => {
 *   return b.getAttribute('data-last-modified') - a.getAttribute('data-last-modified')
 * });
 *
 * @param parent
 * @param compareFunc
 */
export function sortChildElements (parent, compareFunc) {
  [...parent.children]
  .sort(compareFunc)
  .forEach(elem => parent.appendChild(elem));
}

/**
 * Converts the supplied htmlString to an HTML element or an arry of HTML elements.
 *
 * @param htmlString The HTML string to convert to one or more elements.
 *
 * @returns A single element or an array of elements (if the source HTML string has multiple root elements).
 */
export function htmlStringToElements (htmlString= '') {
  let template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  let children = [...template.content.childNodes];

  if (children.length > 1) {
    return children;
  } else if (children.length === 1) {
    return children[0];
  } else {
    return null;
  }
}

/**
 * Indicates whether the current application is embedded in an HTML <iframe>.
 *
 * @returns {boolean}
 */
export function isIframed () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function hasLocalStorage () {
  try {
    if (typeof localStorage === 'undefined') {
      return false;
    } else {
      try {
        localStorage.setItem('testkey', 'testvalue');
        localStorage.removeItem('testkey');
        return true;
      } catch (e) {
        return false;
      }
    }
  } catch (e) {
    return false;
  }
}
