// =================================================================================================
// MRTS RUN STATES
// =================================================================================================
export const STATE_INTRODUCTION = 'state_introduction';
export const STATE_INSTRUCTIONS = 'state_instructions';
export const STATE_MAIN_COUNTDOWN = 'state_main_countdown';
export const STATE_MAIN_COUNTDOWN_ENDPAUSE = 'state_main_countdown_endpause';
export const STATE_SCENE = 'state_scene';
export const STATE_SCENE_RESULT = 'state_scene_result';
export const STATE_SCENE_SCOREBOARD = 'state_scene_scoreboard';
export const STATE_END = 'state_end';
export const STATE_WINNER = 'state_winner';
export const STATE_MAIN_SCOREBOARD = 'state_main_scoreboard';
export const STATE_INTERMISSION = 'state_intermission';

// =================================================================================================
// SCENE CONSTANTS
// =================================================================================================
export const SCENE_TYPE_QUIZ = 'trivia';

// =================================================================================================
// PRODUCT CONSTANTS
// =================================================================================================
/**
 * Name of the group of those products considered "multichoice products". E.g., Hot or Not. For a
 * list of products in the group, see TITLE_PRODUCTS.
 */
export const MULTICHOICE_PRODUCT = 'multichoice';

/**
 * Name of the group of those products considered "title products". For a list of products in the
 * group, see TITLE_PRODUCTS.
 */
export const TITLE_PRODUCT = 'title';

/**
 * Name of the group of those products considered "social products". Any product that ends in "totv"
 * is considered a "social products". (e.g, tweettotv, facebooktotv, etc).
 */
export const SOCIAL_PRODUCT = 'social';

/**
 * The official list of "multichoice products" (i.e., scenes with multiple choice questions, such as Reader's Choice and Pic Pick).
 */
export const MULTICHOICE_PRODUCTS = ['readerschoice', 'viewerschoice', 'picpick', 'hotornot','faceoff','livereactions'];

/**
 * The official list of "title products" (i.e., scenes that contain title captions, such as Bulletin and Teaser).
 */
export const TITLE_PRODUCTS = ['bulletin', 'teaser'];

// =================================================================================================
// CLIENT CONSTANTS
// =================================================================================================
export const CLIENT_TYPE_SCREEN = 'screen';
export const CLIENT_TYPE_CONTROLLER = 'controller';


