
export class PostMessage {
  constructor (fieldName, fieldValue = null) {
    this.type = null;
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
    // To reduce the amount of code required in embed scripts, use a simple ID generator instead of UUIDs
    this.originMessageID = `${new Date().getTime()}_${Math.random()}`;
    this.systemID = PostMessage.SYSTEM_ID;
  }

  toPlainObject () {
    return JSON.parse(JSON.stringify(this));
  }
}

PostMessage.GET = 'GET';
PostMessage.SET = 'SET';
PostMessage.RESPONSE = 'RESPONSE';
PostMessage.LOCAL_STORAGE_CHECK = 'LOCAL_STORAGE_CHECK';
PostMessage.SYSTEM_ID = 'MP_FIRST_PARTY_DATA_BRIDGE';
