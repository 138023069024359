/**
 * Indicates whether a value exists and can be operated on. This function is provides a standardized
 * mechanism for writing code that avoids triggering reference errors.
 */
export function isNil (value) {
  // null, undefined, and NaN are blank
  return value === null || value === undefined || Number.isNaN(value);
}

export function notNil (value) {
  return !isNil(value);
}

/**
 * Returns true if value is any of the following:
 * null
 * undefined
 * NaN
 * empty string
 * empty array
 * empty Map
 * empty Set
 * object with no properties
 */
export function isEmpty (value) {
  if (isNil(value)) {
    return true;
  } else {
    if (typeof value === 'string' || value instanceof Array) {
      return value.length === 0;
    }
    if (value instanceof Set || value instanceof Map) {
      return value.size === 0;
    }
    if (typeof value === 'object') {
      return Object.keys(value).length === 0;
    }
  }
  return false;
}

export function notEmpty (value) {
  return !isEmpty(value);
}

/**
 * Invokes the specified function once for every property of the specified object and for all
 * properties of that object's descendents. I.e., traverses the object tree.
 *
 * Example that logs every key/value pair of obj and its descendents:
 * let o = {id: '1234', type: 'media', uri: { small: 'cat-small.jpg', original: 'cat.jpg'}}
 * let logProps = (key, value, obj) => console.log(key, value);
 * traverse(o, logProps);
 *
 * @param o The root object to traverse.
 * @param func The function to invoke on all properties in the object hierarchy. Expects three
 *           parameters: key, value, obj
 */
export function traverse (o, func) {
  let stack = isNil(o) ? [] : [o];

  while (stack.length) {
    let obj = stack.shift();

    Object.keys(obj).forEach((key) => {
      func(key, obj[key], obj);
      if (obj[key] instanceof Object) {
        stack.unshift(obj[key]);
      }
    });
  }
}


/**
 * Formats the duration of the supplied "elapsed time" object as follows:
 * 3y 2m 1w 5d 22:55:09
 *
 * The "elapsed time" object is returned by moment.preciseDiff(), a function defined by
 * "Moment Precise Range," a moment.js library plugin.
 *
 * Example:
 *   let now = moment();
 *   // Pass true to request a value object rather than a formatted string
 *   let elapsed = moment.preciseDiff(somePastMoment, now, true);
 *   console.log(formatElapsed(elapsed));
 *
 * @param elapsed An 'elapsed' object returned by moment.preciseDiff().
 * @param trimTo One of null, 'minutes', or 'seconds'. When trimTo is null, hours, minutes, and
 *               seconds are all included in the formatted string, even when they are empty
 *               (e.g., 00:00:13). When trimTo is 'minutes' and the elapsed time is less than one
 *               hour, hours are omitted from the formatted string (e.g., 00:13). When trimTo is
 *               'seconds' and the elapsed time is less than one minute, hours AND minutes are
 *               omitted from the formatted string (e.g., :13).
 * @param leadingZeroHours If true, a zero will be added in front of any hours value less than 10.
 * @param leadingZeroMinutes If true, when there are fewer than 59 minutes in the elapsed time,
 *                           a zero will be added in front of any hours value less than 10.
 * @param leadingZeroSeconds If true, when there are fewer than 59 seconds in the elapsed time,
 *                           a zero will be added in front of any hours value less than 10.
 */
export function formatElapsed (elapsed,
                               trimTo = null,
                               leadingZeroHours = true,
                               leadingZeroMinutes = true,
                               leadingZeroSeconds = true) {

  var result = [];

  if (elapsed.years) {
    result.push(elapsed.years + 'y');
  }
  if (elapsed.months) {
    result.push(elapsed.months + 'm');
  }
  if (elapsed.weeks) {
    result.push(elapsed.weeks + 'w');
  }
  if (elapsed.days) {
    result.push(elapsed.days + 'd');
  }

  let hoursMinutesSecondsString = '';
  let hours = (leadingZeroHours && elapsed.hours < 10 ? '0' : '') + elapsed.hours;
  let minutes = ((leadingZeroMinutes || elapsed.hours > 0) && elapsed.minutes < 10 ? '0' : '') + elapsed.minutes;
  let seconds = ((leadingZeroSeconds || elapsed.hours > 0 || elapsed.minutes > 0) && elapsed.seconds < 10 ? '0' : '') + elapsed.seconds;

  if (isNil(trimTo) || elapsed.hours > 0) {
    hoursMinutesSecondsString += `${hours}:`;
  }

  if (isNil(trimTo) || trimTo === 'minutes' || elapsed.minutes > 0) {
    hoursMinutesSecondsString += `${minutes}`;
  }

  hoursMinutesSecondsString += `:${seconds}`;

  result.push(hoursMinutesSecondsString);

  return result.join(' ');
}

/**
 * Returns true if the JSON representation of obj1 is identical to the JSON
 * representation of obj2; else returns false.
 *
 * @returns {boolean}
 */
export function areJSONEqual (obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * Combines the provided array of iterable objects into a single new Set, which it returns.
 *
 * @param iterables
 * @returns {Set<any>}
 */
export function union (...iterables) {
  const set = new Set();

  for (let iterable of iterables) {
    for (let item of iterable) {
      set.add(item);
    }
  }

  return set;
}

/**
 * Removes the first element from a Set or Map and returns that removed element.
 */
export function shiftFromCollection (collection) {
  let key = collection.keys().next().value;
  collection.delete(key);
  return key;
}

/**
 * Invokes the specified function on the specified object if the object has a method with the name
 * functionName.
 *
 * @param obj
 * @param functionName
 */
export function invokeIfDefined (obj, functionName) {
  if (typeof obj[functionName] === 'function') {
    obj[functionName]();
  }
}

/**
 * Returns true if the item has a valid .length property. Use this implementation instead of lodash's
 * isArrayLike() because it doesn't consider strings "array like" (lodash's version does).
 */
export function isArrayLike (item) {
  return (
    Array.isArray(item) ||
    (notNil(item) &&
      typeof item === 'object' &&
      typeof (item.length) === 'number')
  );
}

export function hexToRgb (hex) {
  return isEmpty(hex) ? hex : hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
    ,(m, r, g, b) => '#' + r + r + g + g + b + b)
  .substring(1).match(/.{2}/g)
  .map(x => parseInt(x, 16));
}

export function hexToCSSRgba (hex, opacity) {
  let rgb = hexToRgb(hex);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${notEmpty(opacity) ? opacity : 1})`;
}

export function stringToInt (value) {
  value = notNil(value) && value !== '' ? parseInt(value) : 0;
  return isNaN(value) ? 0 : value;
}

/**
 * Returns the weighted average vote for the specified array of votes. Used for
 * audience-average questions such as Five Stars and Guess Gauge.
 *
 * @param votes An array of votes. E.g., for Five Stars: [23, 2, 3, 44, 97]
 * @returns {number} The weighted average vote as an integer percentage. E.g., for "3/5 stars", returns 60.
 */
// TODO: Determine why MegaController and Flash MegaScreen both return 1.8 as the average for [1,1,1,4],
//  but NEO returns 1.7.
export function getAverageVote (votes) {
  let averageVote = 0;
  let weightedVotesSum = 0;
  let numVotes = sum(votes);
  for (let i = 0; i < votes.length; i++) {
    weightedVotesSum += ((i+1) * votes[i]);
  }
  averageVote = (weightedVotesSum/(votes.length * numVotes))*100;
  averageVote = isNaN(averageVote) ? 0 : averageVote;

  return averageVote;
}

/**
 * Given a list of property paths, returns the first property that has a non-nil value. If all
 * values for all properties in the specified paths array are nil, returns the specified defaultValue.
 *
 * To EXCLUDE empty values from the cascading property-retrieval, use cascadingGetIfNotEmpty().
 *
 * Example:
 *   // Retrieves the question text if it's available, or the title text if not. If neither
 *   // question nor title text are found, returns the empty string.
 *
 *   let mastheadTitleText = cascadingGet(scene,
 *     ['content.question.text',
 *     'content.title.text'],
 *     '');
 *
 * @param object An object with properties to search (typically JSON).
 * @param paths An array of deep paths to properties on the specified object.
 * @param defaultValue The value returned if all specified properties are nil or empty.
 * @returns {*}
 */
export function cascadingGet (object, paths, defaultValue) {
  if (notNil(paths)) {
    for (var i = 0; i < paths.length; i++) {
      let path = paths[i];
      let value = get(object, path);
      // WILL return "empty" values
      if (notNil(value)) {
        return value;
      }
    }
  }
  return notNil(defaultValue) ? defaultValue : null;
}

/**
 * Given a list of property paths, returns the first property that has a non-nil AND non-empty
 * value, as determined by isEmpty(). If all values for all properties in the specified paths array
 * are nil or empty, returns the specified defaultValue.
 *
 * To INCLUDE empty values, such as the empty string, in the cascading property retrieval,
 * use cascadingGet().
 *
 * Multiple-object cascade:
 *   // Returns the value of 'scene.content.cta.text' unless it is null, undefined, or the empty
 *   // string, in which case, returns:
 *   // MegaScreen.globals.dataStore.deploymentSettings.defaults.content.cta.strings.general.text
 *   // Notice that the default value of the first cascadingGet() is another call to cascadingGet().
 *
 *   let ctaText = cascadingGetIfNotEmpty(scene,
 *     ['content.cta.text'],
 *     cascadingGet(MegaScreen.globals.dataStore.deploymentSettings,
 *       [`defaults.content.cta.strings.general.text`]));
 *
 * @param object An object with properties to search (typically JSON).
 * @param paths An array of deep paths to properties on the specified object.
 * @param defaultValue The value returned if all specified properties are nil or empty.
 * @returns {*}
 */
export function cascadingGetIfNotEmpty (object, paths, defaultValue = null) {
  if (notNil(paths)) {
    for (var i = 0; i < paths.length; i++) {
      let path = paths[i];
      let value = get(object, path);
      // WON'T return "empty" values
      if (notEmpty(value)) {
        return value;
      }
    }
  }
  return notNil(defaultValue) ? defaultValue : null;
}

/**
 * If the property specified by path on object is not empty, returns the property's value;
 * otherwise, returns the defaultValue.
 */
export function getIfNotEmpty (object, path, defaultValue = null) {
  let value = get(object, path);
  return isEmpty(value) ? defaultValue : value;
}

/**
 * In the specified str, replaceAll() finds and replaces all keys/values of the specified mapObj,
 * and returns the resulting string.
 *
 * Examples:
 * // Returns the string 'wolf lion rabbit'
 * replaceAll('dog cat rabbit', {'dog': 'wolf', 'cat': 'lion'})
 *
 * // Real world example
 * // Given the following source str...
 * locale.GO_TO_FEEDBACK_WARNING_2 = 'A feedback form will appear on all user devices (currently %1 %2).',
 *
 * ...replace %1 with numUsers and %2 with either locale.USER_SINGULAR or locale.USER_PLURAL
 * warning2.textContent = replaceAll(locale.GO_TO_FEEDBACK_WARNING_2,
 *   {
 *    '%1': numUsers,
 *    '%2': numUsers === 1 ? locale.USER_SINGULAR : locale.USER_PLURAL
 *   });
 *
 * @param str The source string that contains the terms to be replaced.
 * @param mapObj A plain JavaScript object of keys/values to be replaced in str.
 *
 * @returns {string} A new string with the replaced values.
 */
export function replaceAll (str, mapObj) {
  Object.keys(mapObj).forEach(key => {
    str = str.split(key).join(mapObj[key]);
  });

  return str;
}

/**
 * Returns true if the supplied url (string) is a relative URL such as "../config.json". Otherwise,
 * if the supplied url is an absolute URL such as "http://example.com/config.json", returns false.
 * This function requires a browser-supplied document object or equivalent.
 *
 * @param url A string URL.
 * @returns {boolean}
 */
export function isRelativeURL (url) {
  return new URL(document.baseURI).origin === new URL(url, document.baseURI).origin;
}

/**
 * Returns the function funcName (which must be defined by obj), bound to obj. This is a convenience
 * wrapper intended to improve the readability of function binding, which is often used when registering
 * listeners, as in:
 * someTask.on(COMPLETE, this.completeListener.bind(this));
 *
 * Example:
 * someTask.on(COMPLETE, bind(this, "completeListener"));
 *
 * @param obj
 * @param funcName
 */
export function bind (obj, funcName) {
  return obj[funcName].bind(obj);
}

/**
 * Runs the supplied list of functions in sequential order, one at a time. Each function in the
 * list must return a promise. When the Promise resolves, the next function in the sequence is
 * executed. If any of the Promises rejects, serial() fails with its own Promise rejection.
 *
 * @param funcs A comma-separated list of functions to execute in the supplied order; each function
 *              must return a Promise indicating when its task is complete.
 */
export function serial (...funcs) {
  let current = 0;

  return new Promise((resolve, reject) => {
    function next() {
      funcs[current]().then(() => {
          current++;
          if (current < funcs.length) {
            next();
          } else {
            resolve();
          }
        }
      ).catch(reason => {
          reject(reason);
        }
      );
    }

    if (notEmpty(funcs)) {
      next();
    } else {
      resolve();
    }
  });
}

/**
 * Converts a string from "snake case" to "title case". For example:
 * snakeToTitleCase('main_countdown_points_label');  // Output: Main Countdown Points Label
 *
 * This function is useful for converting JSON locale string tables to human-readable labels.
 *
 * @param str
 * @returns {string}
 */
export function snakeToTitleCase (str) {
  return str.split('_')
  .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
  .join(' ');
}
