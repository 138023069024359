export const locale = {};
locale.en = {};
locale.en.CONNECTING = "Connecting...";
locale.en.BOOT_FAIL = "Please try again later.";
locale.en.NO_LOCAL_STORAGE = "Please enable cookies or disable private browsing and try again.";
locale.en.JAVASCRIPT_INCOMPATIBLE = "We're sorry, but your web browser is not supported.";
locale.es = {};
locale.es.CONNECTING = "Cargando...";
locale.es.BOOT_FAIL = "Por favor trate mas tarde.";
locale.es.NO_LOCAL_STORAGE = "Por favor, activa las cookies y vuelve a intentarlo.";
locale.es.JAVASCRIPT_INCOMPATIBLE = "Su browser no es compatible, por favor trate otro browser o navegador.";
locale.current = locale.en;
