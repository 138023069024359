import {PostMessage} from './PostMessage.js';
import {ResponseMessage} from './ResponseMessage.js';
import {hasLocalStorage, isIframed} from '../DOM.js';

export class FirstPartyDataMessageBubbler {

  constructor () {
    this.targetOrigin = '*';
    this.bubblingMessages = new Map();
    this.beginListeningForMessages();
  }

  beginListeningForMessages () {
    window.addEventListener('message', this.messageListener.bind(this), false);
  }

  /**
   * Responds to all incoming post messages.
   */
  messageListener (e) {
    if (e.data.systemID !== PostMessage.SYSTEM_ID) {
      // This message is not part of the first-party data bridge, so ignore it.
      return;
    }

    let messageType = e.data.type;
    let fieldName = e.data.fieldName;
    let fieldValue = e.data.fieldValue;

    let responseMessage = new ResponseMessage();
    responseMessage.originMessageID = e.data.originMessageID;

    if (messageType === PostMessage.GET) {
      // RECEIVED GET REQUEST FROM FOREIGN WINDOW
      responseMessage.fieldName = fieldName;

      if (hasLocalStorage()) {
        this.handleGet(fieldName, e.source, responseMessage)
      } else {
        this.checkForBubble(e.data, e.source, responseMessage);
      }
    } else if (messageType === PostMessage.SET) {
      // RECEIVED SET REQUEST FROM FOREIGN WINDOW
      if (hasLocalStorage()) {
        this.handleSet(fieldName, fieldValue);
      } else {
        this.checkForBubble(e.data, e.source, responseMessage)
      }
    } else if (messageType === PostMessage.LOCAL_STORAGE_CHECK) {
      // RECEIVED LOCAL_STORAGE_CHECK FROM FOREIGN WINDOW
      if (hasLocalStorage()) {
        this.handleLocalStorageCheck(e.source, responseMessage);
      } else {
        this.checkForBubble(e.data, e.source, responseMessage);
      }
    } else if (messageType === PostMessage.RESPONSE) {
      // RECEIVED RESPONSE FROM FOREIGN WINDOW
      const originatingMessage = this.bubblingMessages.get(e.data.originMessageID);
      if (originatingMessage != null) {
        this.bubblingMessages.delete(e.data.originMessageID);
        originatingMessage.source.postMessage(e.data, this.targetOrigin);
      }
    }
  }

  handleGet (fieldName, source, responseMessage) {
    responseMessage.fieldValue = localStorage.getItem(fieldName);
    responseMessage.status = 200;
    source.postMessage(responseMessage.toPlainObject(), this.targetOrigin);
  }

  handleSet (fieldName, fieldValue) {
    localStorage.setItem(fieldName, fieldValue);
  }

  handleLocalStorageCheck (source, responseMessage) {
    responseMessage.status = 200;  // OK
    source.postMessage(responseMessage.toPlainObject(), this.targetOrigin);
  }

  checkForBubble (message, source, responseMessage) {
    if (isIframed()) {
      // LocalStorage is not available in this window, so pass the GET message to the
      // parent in the hopes that the parent (or one of its ancestors) has LocalStorage available.
      this.bubbleMessage(message, source);
    } else {
      // LocalStorage is not available, and this is the top window, so pass a 403 back to the
      // sender.
      responseMessage.status = 403;
      responseMessage.fieldValue = null;
      source.postMessage(responseMessage.toPlainObject(), this.targetOrigin);
    }
  }

  bubbleMessage (message, source) {
    this.bubblingMessages.set(message.originMessageID, {message: message, source: source});
    if (message.type === PostMessage.GET || message.type === PostMessage.LOCAL_STORAGE_CHECK) {
      // GET and LOCAL_STORAGE_CHECK both require responses. If the response is not received,
      // return an error to the sender.
      setTimeout(this.handleBubbledMessageTimeout.bind(this), 4000, message, source);
    }
    window.parent.postMessage(message, this.targetOrigin);
  }

  handleBubbledMessageTimeout (message, source) {
    if (this.bubblingMessages.has(message.originMessageID)) {
      // Response was not received for the postMessage, so stop waiting
      this.bubblingMessages.delete(message.originMessageID);
      message.status = 404;
      message.type = PostMessage.RESPONSE;
      // Inform sender that the request failed (404)
      source.postMessage(message, this.targetOrigin);
    }
  }
}
