import {EventDispatcher} from './EventDispatcher.js';
import {PostMessageEvent} from './PostMessageEvent.js';
import {isEmpty, isNil} from './utils.js';

/**
 * Handles incoming and outgoing messages sent between browser windows and iframes.
 *
 * Example Usage:
 *
 * // Create PostMessageManager instance, send APP_INIT, and register for incoming post messages
 * let postMessageManager = new PostMessageManager();
 * postMessageManager.send(PostMessages.APP_INIT);
 * postMessageManager.on(PostMessageEvent.RECEIVE_MESSAGE, event => {
 *   if (event.detail.message === 'SOME_MESSAGE') {
 *     // Respond to SOME_MESSAGE here
 *   }
 * });
 *
 */
export class PostMessageManager extends EventDispatcher {
  constructor () {
    super();
    window.addEventListener("message", this.receiveMessage.bind(this));
  }

  /**
   * Transmits a message to the specified targetWindow.
   *
   * Example:
   * let postMessgaeManager = new PostMessageManager();
   * let message = {
   *   message: "AGE",
   *   data: 23
   * };
   *
   * postMessageManager.send(message);
   *
   * @param message
   * @param targetOrigin
   * @param targetWindow The recipient of the message. If not specified, defaults to window.parent.
   */
  send (message, targetOrigin, targetWindow) {
    if (isEmpty(targetOrigin)) {
      targetOrigin = '*';
    }
    if (isNil(targetWindow)) {
      targetWindow = window.parent;
    }
    targetWindow.postMessage(message, targetOrigin);
  }

  /**
   * Listener for all incoming postMessage() messages from other windows or iframes
   *
   * @param event
   */
  receiveMessage (event) {
    this.dispatchEvent(PostMessageEvent.RECEIVE_MESSAGE, {message: event.data, source: event.source});
  }
}
