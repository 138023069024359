import {Controller} from './Controller.js';

export class SystemSettings {
  constructor () {
    this.debugLevel = null;
    this.heartbeatFrequency = 0;
    this.connectionTimeout = 0;
    this.httpRetryDelay = 0;
    this.autoReconnectMinMS = 0;
    this.autoReconnectMaxMS = 0;
    this.autoReconnectDelayFirstAttempt = false;
    this.autoReconnectAttemptLimit = 0;
    this.autojoinPercent = Controller.DEFAULT_AUTOJOIN_PERCENT;
    this.manualjoinPercent = Controller.DEFAULT_MANUALJOIN_PERCENT;
  }
}
