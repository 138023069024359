import {Event} from './Event.js';

export class ControllerEvent extends Event {
  constructor (type, errorCode, errors, taskName) {
    super(type);
    this.errorCode = errorCode;
    this.errors    = errors;
    this.taskName  = taskName;
  }

  getTaskName () {
    return this.taskName;
  }

  toString () {
    return "[object ControllerEvent]";
  }
}

ControllerEvent.SETUP                   = "SETUP";
ControllerEvent.BEGIN_CONNECT           = "BEGIN_CONNECT";
ControllerEvent.CONNECT_FAILURE         = "CONNECT_FAILURE";
ControllerEvent.JAVASCRIPT_INCOMPATIBLE = "JAVASCRIPT_INCOMPATIBLE";
ControllerEvent.NO_LOCAL_STORAGE        = "NO_LOCAL_STORAGE";
ControllerEvent.READY                   = "READY";  // Occurs after the controller receives CONTROLLER_READY from the server
ControllerEvent.DISCONNECT              = "DISCONNECT";
ControllerEvent.QUIT                    = "QUIT";
ControllerEvent.BOOT_FAILED             = "BOOT_FAILED";
ControllerEvent.BOOT_COMPLETE           = "BOOT_COMPLETE";
ControllerEvent.BOOT_TASK_COMPLETE      = "BOOT_TASK_COMPLETE";
ControllerEvent.APPLICATION_ERROR       = "APPLICATION_ERROR";
ControllerEvent.SESSION_TERMINATED      = "SESSION_TERMINATED";
