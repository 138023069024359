/**
 * Utilities ported from MegaController's original networking library, MegaPhoneJSController. Not to
 * be confused with the newer mputils ES6 library, which is used everywhere except in legacy code
 * that was ported directly from MegaphoneJSController to avoid API changes for the sake of
 * consumers such as MegaController.
 */

//==============================================================================
// CONFIG FILE LOADING
//==============================================================================
export const utils = {};
utils.load = function (url, completeCallback, errorCallback, method, vars, nocache) {
  var request = new XMLHttpRequest();
  request.onerror = errorCallback;
  request.onreadystatechange = function (state) {
    if (request.readyState == 4) {
      completeCallback(request);
    }
  }

  var noCacheParameter = (nocache === true ? "nocache=" + new Date().getTime() : "");
  var queryParamsString = "";
  for (var p in vars) {
    queryParamsString += p + "=" + encodeURI(vars[p]) + "&";
  }
  if (queryParamsString !== "") {
    // Remove & that was added after the final query-string parameter
    queryParamsString = queryParamsString.substring(0, queryParamsString.length-1);
  }

  var fullQueryString = "";
  if (queryParamsString === "") {
    fullQueryString = noCacheParameter;
  } else {
    fullQueryString = queryParamsString + (nocache ? "&" + noCacheParameter : "");
  }

  if (method === "GET" || method == null) {
    request.open("GET", url + (fullQueryString === "" ? fullQueryString : "?" + fullQueryString));
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    request.send(null);
  } else {
    request.open(method, url);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    request.send(vars==null?null:query);
  }
}
