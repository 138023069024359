import {PostMessageManager} from '../PostMessageManager.js';
import {SetMessage} from './SetMessage.js';
import {GetMessage} from './GetMessage.js';
import {EventDispatcher} from '../EventDispatcher.js';
import {PostMessage} from './PostMessage.js';
import {PostMessageEvent} from '../PostMessageEvent.js';
import {FirstPartyStorageEvent} from './FirstPartyStorageEvent.js';
import {hasLocalStorage, isIframed} from '../DOM.js';
import {ResponseMessage} from './ResponseMessage.js';
import {CheckFirstPartyLocalStorage} from './CheckFirstPartyLocalStorage.js';

export class FirstPartyStorageService extends EventDispatcher {

  constructor() {
    super();
    this.postMessageManager = new PostMessageManager();
    this.postMessageManager.on(PostMessageEvent.RECEIVE_MESSAGE, this.receiveMessageListener.bind(this));
    this.sentMessageIDs = new Set();
  }

  checkFirstPartyLocalStorage () {
    let message = new CheckFirstPartyLocalStorage();
    this.sentMessageIDs.add(message.originMessageID);

    let response = new ResponseMessage();
    response.originMessageID = message.originMessageID;

    let doHandleReceivedMessage = false;

    if (hasLocalStorage()) {
      // Local storage is available, so respond with 200 (OK)
      response.status = 200;
      doHandleReceivedMessage = true;
    } else {
      // Local storage is not available, so check if this is the top window...
      if (isIframed()) {
        // ...this is not the top window, so send the local-storage check to the parent window
        this.postMessageManager.send(message);
      } else {
        // ...this is the top window, and local storage is not available, so respond with 403 (forbidden)
        response.status = 403;
        doHandleReceivedMessage = true;
      }
    }

    if (doHandleReceivedMessage) {
      // Allow checkFirstPartyLocalStorage() to return an ID before triggering the RESPONSE event
      setTimeout(() => {this.handleReceivedMessage(response, null)}, 10);
    }

    return message.originMessageID;
  }

  remove (key) {
    return this.set(key, null);
  }

  get (key) {
    let message = new GetMessage(key);
    this.sentMessageIDs.add(message.originMessageID);

    let response = new ResponseMessage();
    response.fieldName = key;
    response.originMessageID = message.originMessageID;

    let doHandleReceivedMessage = false;

    if (hasLocalStorage()) {
      response.fieldValue = localStorage.getItem(key);
      response.status = 200;
      doHandleReceivedMessage = true;
    } else {
      if (isIframed()) {
        this.postMessageManager.send(message);
      } else {
        response.status = 403;
        doHandleReceivedMessage = true;
      }
    }

    if (doHandleReceivedMessage) {
      // Allow get() to return an ID before triggering the RESPONSE event
      setTimeout(() => {this.handleReceivedMessage(response, null)}, 10);
    }

    return message.originMessageID;
  }

  set (key, value) {
    let message = new SetMessage(key, value);
    this.sentMessageIDs.add(message.originMessageID);

    if (hasLocalStorage()) {
      localStorage.setItem(key, value);
    } else {
      if (isIframed()) {
        this.postMessageManager.send(message);
      } else {
        // Fail silently. Future consideration: Send a ResponseMessage to the caller via
        // handleReceivedMessage (as in get() implementation).
      }
    }

    return message.originMessageID;
  }

  receiveMessageListener (e) {
    this.handleReceivedMessage(e.detail.message, e.detail.source);
  }

  handleReceivedMessage (message, source) {
    if (message.systemID !== PostMessage.SYSTEM_ID) {
      // This message is not part of the first-party data bridge, so ignore it.
      return;
    }

    if (message.type === PostMessage.RESPONSE && this.sentMessageIDs.has(message.originMessageID)) {
      this.sentMessageIDs.delete(message.originMessageID);
      this.dispatchEvent(FirstPartyStorageEvent.RESPONSE, {message: message, source: source});
    }
  }
}
