import {PostMessageManager} from './captivelibs/mputils/PostMessageManager.js';
import {PostMessageEvent} from './captivelibs/mputils/PostMessageEvent.js';
import {Controller} from './Controller.js';
import {CheckFirstPartyLocalStorage} from './captivelibs/mputils/postmessage/CheckFirstPartyLocalStorage.js';
import {GetMessage} from './captivelibs/mputils/postmessage/GetMessage.js';
import {SetMessage} from './captivelibs/mputils/postmessage/SetMessage.js';

/**
 * Transmits controller data between the current window and its parent.
 */
export class EmbedBridge {
  constructor () {
    this.postMessageManager = new PostMessageManager();
  }

  //================================================================================================
  // MESSAGES TO PARENT (PAGE ON WHICH CONTROLLER IS EMBEDDED
  //================================================================================================
  getLocalStorageEnabled () {
    let message = new CheckFirstPartyLocalStorage();
    this.send(message);
    return message.originMessageID;
  }

  getControllerKey () {
    let message = new GetMessage(Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_KEY);
    this.send(message);
    return message.originMessageID;
  }

  setControllerKey (key) {
    let message = new SetMessage(Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_KEY, key);
    this.send(message);
  }

  get (fieldName) {
    let message = new GetMessage(fieldName);
    this.send(message);
  }

  set (fieldName, fieldValue) {
    let message = new SetMessage(fieldName, fieldValue);
    this.send(message);
  }

  /**
   * Sends a message to the target window.
   */
  send (message) {
    this.postMessageManager.send(message);
  }
}

