export class DataStore {
  constructor () {
    this.accountKey = null;
    this.cookiesRequired = true;
    this.environment = null;
    this.megaphoneToken = null;
    this.mrtsEndpoint = null;
    this.deploymentID = null;
    this.deploymentKey = null;
    this.deploymentPassword = null;
    this.nexusResponsePayload = null;
  }
}
