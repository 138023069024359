import {ControllerEvent} from './ControllerEvent.js';
import {SystemSettings} from './SystemSettings.js';
import {ChannelBridge} from './captivelibs/mrtsclient/ChannelBridge.js';
import {EventDispatcher} from './EventDispatcher.js';
import {isEmpty, notNil} from './captivelibs/mputils/utils.js';
import {ClientMessages} from './ClientMessages.js';
import {PostMessages} from './captivelibs/mrtsclient/PostMessages.js';
import {EmbedBridge} from './EmbedBridge.js';
import {hasLocalStorage, isIframed} from './captivelibs/mputils/DOM.js';
import {GetMRTSRouteTask} from './captivelibs/mrtsclient/GetMRTSRouteTask.js';
import {CLIENT_TYPE_CONTROLLER} from './captivelibs/mrtsclient/constants.js';
import {DataStore} from './DataStore.js';
import {COMPLETE, FAIL} from './captivelibs/mputils/constants.js';
import {PostMessage} from './captivelibs/mputils/postmessage/PostMessage.js';
import {PostMessageEvent} from './captivelibs/mputils/PostMessageEvent.js';

/**
 * To begin using Controller, from the /dist/ folder, first load Orbiter.js as type "text/javascript"
 * and controllerio.js as type "module". To allow hybrid usage of ControllerIO (which is partly ES6)
 * in ES5 applications such as AngularJS, ControllerIO defines a global variable, 'megaphone', on
 * the global window object. The megphone variable is used in legacy code original written in
 * MegaPhoneJSController, the predecessor to ControllerIO.
 *
 * Example (assuming the /dist/ files have been copied to the current directory):
 *   <script type="text/javascript" src="lib/Orbiter.js"></script>
 *   <script type="module" src="controllerio.js"></script>
 *
 * @param externalMRTSRoute Optional. A MRTS "route" object in the exact format that would be returned
 *                  by Nexus from /mrts/controller. If externalMRTSRoute is supplied, then during the
 *                  boot process Controller will skip contacting Nexus, and will instead connect to
 *                  MRTS at the host/port specified by the supplied route. Clients such as
 *                  MegaController supply the externalMRTSRoute in cases where they contact
 *                  Nexus in advance to determine the current content destination, and then connect
 *                  to MRTS only if MRTS is the specified the destination. Other non-MRTS
 *                  destinations might, for example, be a web poll or an album.
 *
 *                  If externalMRTSRoute is not specified, then Controller connects to Nexus
 *                  directly at /mrts/controller to determine the MRTS host/port to connect to.
 *
 *                  For further details, see:
 *                  https://docs.google.com/document/d/1kxkB6Y7BAo05f963_KlcxeQ9CmkAdRsS7UPeMtw5kW4/
 */
export class Controller extends EventDispatcher {

  constructor (applicationConfigURL,
               appSettingsRegistry,
               externalMRTSRoute) {
    // Invoke superclass constructor
    super();

    Controller.globals.dataStore = new DataStore();

    this.orbiter = new net.user1.orbiter.Orbiter();
    this.orbiter.getLog().info("ControllerIO loaded.");

    // Instance variables
    this.systemSettings = new SystemSettings();
    this.appSettingsRegistry = appSettingsRegistry;
    this.applicationConfigURL = applicationConfigURL;
    this.roomID = null;
    this.numClosesSinceLastReady = 0;
    this.callerLabel = null;
    this.userDefinedLabel = "";
    this.controllerNumber = "";
    this.controllerType = "web";
    this.deploymentKey = null;
    this.bootFailed = false;
    this.instanceShortcode = "";
    this.real = true;
    this.connectDuringBoot = true;
    this.ready = false;
    this.readyCount = 0;
    this.appErrorCount = 0;
    this.leftRoomCount = 0;
    // StreamClick communications
    this.channelBridge = new ChannelBridge();
    // Parent window communications
    this.embedBridge = new EmbedBridge();
    this.embedBridge.postMessageManager.on(PostMessageEvent.RECEIVE_MESSAGE, this.receivePostMessageListener.bind(this));
    this.role = '';
    this.position = '';
    this.source_id = '';
    this.invitation_password = '';
    this.studio_token = '';
    this.storageCheckTimeoutID = -1;
    this.externalMRTSRoute = externalMRTSRoute;

    if (typeof navigator != "undefined") {
      this.userAgent = navigator.userAgent+" "+navigator.platform;
    } else {
      this.userAgent = "";
    }

    if (this.orbiter.getSystem().isJavaScriptCompatible()) {
      this.orbiter.addEventListener(net.user1.orbiter.OrbiterEvent.READY, this.readyListener, this);
      this.orbiter.addEventListener(net.user1.orbiter.OrbiterEvent.CLOSE, this.closeListener, this);
      this.orbiter.getConnectionManager().addEventListener(net.user1.orbiter.ConnectionManagerEvent.BEGIN_CONNECT, this.beginConnectListener, this);
      this.orbiter.getConnectionManager().addEventListener(net.user1.orbiter.ConnectionManagerEvent.CONNECT_FAILURE, this.connectFailureListener, this);
      this.orbiter.getConnectionManager().addEventListener(net.user1.orbiter.ConnectionManagerEvent.DISCONNECT, this.disconnectListener, this);
      this.addCommandListener(ClientMessages.CONNECT_CONTROLLER_FAILED, this.connectControllerFailedListener);
    }
  }

//==============================================================================
// CONTROLLER BOOT
//==============================================================================
  boot () {
    this.orbiter.getLog().info("Initiating boot sequence...");
    if (!this.orbiter.getSystem().isJavaScriptCompatible()) {
      this.dispatchJavaScriptIncompatible();
      this.doBootFail("Boot failed at task [JAVASCRIPT_CHECK]. Required JavaScript capabilities are missing.");
      return;
    }

    this.loadExternalConfiguration();
  }

  loadExternalConfiguration () {
    // Retrieve query params required for StreamClick setup (normally assigned by the Multiview)
    let requiredQueryParamsAvailable = this.processQueryParams();

    if (requiredQueryParamsAvailable || notNil(this.externalMRTSRoute)) {
      let getMRTSRouteTask = new GetMRTSRouteTask(Controller.globals.dataStore, CLIENT_TYPE_CONTROLLER, this.externalMRTSRoute);
      getMRTSRouteTask.on(COMPLETE, (e) => {
        this.getMRTSRouteComplete();
      });
      getMRTSRouteTask.on(FAIL, (e) => {
        this.getMRTSRouteError();
      });
      getMRTSRouteTask.start();
    } else {
      this.doBootFail('Boot failed. Missing required query parameters.');
    }
  }

  getMRTSRouteComplete () {
    let host = Controller.globals.dataStore.mrtsEndpoint.host;
    let ports = Controller.globals.dataStore.mrtsEndpoint.ports;
    let connection_types = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'connection_types', Controller.DEFAULT_CONNECTION_TYPES);
    let cookies_required = Controller.globals.dataStore.cookiesRequired;

    for (let i = 0; i < ports.length; i++) {
      for (let j = 0; j < connection_types.length; j++) {
        this.orbiter.buildConnection(host,
          ports[i],
          connection_types[j],
          (connection_types[j] === net.user1.orbiter.ConnectionType.HTTP
            || connection_types[j] === net.user1.orbiter.ConnectionType.SECURE_HTTP) ? net.user1.orbiter.HTTPConnection.DEFAULT_SEND_DELAY : 0);
      }
    }

    this.systemSettings.debugLevel = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'debug_level', Controller.DEFAULT_DEBUG_LEVEL);
    this.systemSettings.heartbeatFrequency = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'heartbeat_frequency', Controller.DEFAULT_HEARTBEAT_FREQUENCY);
    this.systemSettings.connectionTimeout = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'connection_timeout', Controller.DEFAULT_CONNECTION_TIMEOUT);
    this.systemSettings.httpRetryDelay = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'http_retry_delay', Controller.DEFAULT_HTTP_RETRY_DELAY);
    this.systemSettings.autoReconnectMinMS = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'autoreconnect_minms', Controller.DEFAULT_AUTORECONNECT_MINMS);
    this.systemSettings.autoReconnectMaxMS = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'autoreconnect_maxms', Controller.DEFAULT_AUTORECONNECT_MAXMS);
    this.systemSettings.autoReconnectDelayFirstAttempt = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'autoreconnect_delayfirstattempt', Controller.DEFAULT_AUTORECONNECT_DELAYFIRSTATTEMPT);
    this.systemSettings.autoReconnectAttemptLimit = Controller.getIfNotEmpty(Controller.globals.dataStore.nexusResponsePayload.controller, 'autoreconnect_attempt_limit', Controller.DEFAULT_AUTORECONNECT_ATTEMPT_LIMIT);

    this.dispatchBootTaskComplete(megaphone.CoreBootTasks.LOAD_CONNECTION_CONFIG);

    if (cookies_required) {
      this.doLocalStorageCheck();
    } else {
      this.orbiter.getLog().info('Local storage not required for this project. Controller key will not be sent.');
      this.loadApplicationConfig();
    }
  };

  getMRTSRouteError (e) {
    this.doBootFail("Boot failed at task [GET_MRTS_ROUTE]. Could not load MRTS endpoint from Nexus.");
  };

  doLocalStorageCheck () {
    // Local storage is required in the browswer (where document is defined),
    // but not in non-browser environments such as Node.js.
    if (typeof document !== "undefined") {
      let localStorageAvailable = hasLocalStorage();

      if (localStorageAvailable) {
        // Local storage is available, so access localStorage directly
        this.handleLocalStorageCheck(true);
      } else {
        if (isIframed()) {
          // Ask parent window whether local storage is enabled.
          this.orbiter.getLog().info("Checking parent for local storage...");
          this.checkLocalStorageMessageID = this.embedBridge.getLocalStorageEnabled();
          this.storageCheckTimeoutID = setTimeout(this.storageCheckTimeout.bind(this), 5000);
          // Wait for the response, which will be handled by this.receivePostMessageListener()...
        } else {
          this.handleLocalStorageCheck(false);
        }
      }
    }
  }

  storageCheckTimeout () {
    // No repsonse from parent iframe to local storage check request, so the boot process cannot continue
    this.dispatchNoLocalStorage();
    this.doBootFail(`Boot failed at task [LOCALSTORAGE_CHECK]. Local storage check timed out. Required local storage capabilities are not available.${isIframed() ? ' Embed script is likely missing or needs to be upgraded.' : ''}`);
  }

  /**
   * Responds to a check for local storage that occurred either directly on the current page
   * or that was requested from an ancestor window via the first-party storage bubbler.
   *
   * @param enabled Boolean indicating whether local storage is available, either on the current
   *                page directly or via ancestor window through the first-party storage bubbler.
   */
  handleLocalStorageCheck (enabled) {
    if (this.bootFailed) {
      return;
    }
    clearTimeout(this.storageCheckTimeoutID);

    if (enabled) {
      // Local storage is enabled in the browser at some level in the embed hierarchy, so continue
      // the boot process...
      this.orbiter.getLog().info("Local storage check passed. Continuing boot process...");

      // Check if local storage is directly accessible to the current page.
      if (hasLocalStorage()) {
        // Local storage is available to the current page, so retrieve controller key directly
        // from localStorage.
        this.handleControllerKeyRetrieval(localStorage.getItem('mp_controller_key'));
      } else {
        if (isIframed()) {
          // Local storage is available, but via an ancestor window, not the current page, so
          // request the controller key from the parent window.
          this.getControllerKeyMessageID = this.embedBridge.getControllerKey();
          // Wait for the response, which will be handled by this.receivePostMessageListener()...
        }
      }
    } else {
      // Local storage is disabled in the browser, so the boot process cannot continue
      this.dispatchNoLocalStorage();
      this.doBootFail("Boot failed at task [LOCALSTORAGE_CHECK]. Required local storage capabilities are missing.");
    }
  }

  handleControllerKeyRetrieval (key) {
    this.setControllerKey(key);

    // Controller key has been received, so continue the boot processs...
    this.loadApplicationConfig();
  }

  loadApplicationConfig () {
    var self = this;
    megaphone.utils.load(this.applicationConfigURL,
      function (responseXML) {self.applicationConfigComplete(responseXML)},
      function (responseXML) {self.applicationConfigError(responseXML)});
  }

  doBootFail (msg) {
    if (!this.bootFailed) {
      this.bootFailed = true;
      this.orbiter.getLog().fatal(msg);
      this.dispatchBootFailed();
    }
  }

//==============================================================================
// GET MRTS ROUTE TASK LISTENERS
//==============================================================================


//==============================================================================
// CONTROLLER LOAD APP CONFIG
//==============================================================================
  applicationConfigComplete (request) {
    var config = request.responseXML;
    if ((request.status != 200 && request.status != 0) || config == null) { // Config is null if the file didn't load
      this.doBootFail("Boot failed at task [LOAD_APPLICATION_CONFIG]. File [" + this.applicationConfigURL + "] failed to load.");
      return;
    }

    var loadedAppSettings    = config.getElementsByTagName("application")[0].getElementsByTagName("setting");
    var setting;

    try {
      for (var i = 0; i < loadedAppSettings.length; i++) {
        setting = loadedAppSettings[i];
        if (setting.childNodes.length == 0) {
          this.appSettingsRegistry[setting.getAttribute("name")] = "";
        } else if (setting.childNodes.length == 1 && setting.firstChild.nodeType == 3) {
          this.appSettingsRegistry[setting.getAttribute("name")] = setting.firstChild.nodeValue;
        } else {
          // The setting contains XML, so save the entire setting node
          this.appSettingsRegistry[setting.getAttribute("name")] = setting.cloneNode(true);
        }
      }
    } catch (e) {
      this.doBootFail("Boot failed at task [LOAD_APPLICATION_CONFIG]. Invalid configuration file.");
      return;
    }

    this.orbiter.getLog().info("Loaded [" + this.applicationConfigURL + "].");
    this.orbiter.getLog().setLevel(this.systemSettings.debugLevel);
    this.orbiter.getConnectionMonitor().setHeartbeatFrequency(
      this.systemSettings.heartbeatFrequency <= 0
        ? Controller.DEFAULT_HEARTBEAT_FREQUENCY
        : this.systemSettings.heartbeatFrequency);

    this.orbiter.getConnectionMonitor().setConnectionTimeout(
      this.systemSettings.connectionTimeout <= 0
        ? Controller.DEFAULT_CONNECTION_TIMEOUT
        : this.systemSettings.connectionTimeout);

    var connections = this.orbiter.getConnectionManager().getConnections();
    for (var i = 0; i < connections.length; i++) {
      if (connections[i].getType() === net.user1.orbiter.ConnectionType.HTTP || connections[i].getType() === net.user1.orbiter.ConnectionType.SECURE_HTTP) {
        connections[i].setRetryDelay(
          this.systemSettings.httpRetryDelay <= 0
            ? Controller.DEFAULT_HTTP_RETRY_DELAY
            : this.systemSettings.httpRetryDelay);
      }
    }

    this.applyAutoReconnectSettings();

    this.orbiter.getConnectionMonitor().setAutoReconnectAttemptLimit(
      this.systemSettings.autoReconnectAttemptLimit <= 0
        ? Controller.DEFAULT_AUTORECONNECT_ATTEMPT_LIMIT
        : this.systemSettings.autoReconnectAttemptLimit);

    this.dispatchBootTaskComplete(megaphone.CoreBootTasks.LOAD_APPLICATION_CONFIG);

    if (this.connectDuringBoot) {
      this.connect();
    } else {
      this.dispatchBootComplete();
    }
  };

  applyAutoReconnectSettings () {
    if (this.systemSettings.autoReconnectMinMS == -1
      || this.systemSettings.autoReconnectMinMS > 1000) {
      this.orbiter.getConnectionMonitor().setAutoReconnectFrequency(
        this.systemSettings.autoReconnectMinMS,
        this.systemSettings.autoReconnectMaxMS,
        this.systemSettings.autoReconnectDelayFirstAttempt);
    } else {
      this.orbiter.getLog().warn("Illegal auto-reconnect frequency specified: ["
        + this.systemSettings.autoReconnectMinMS + "]. Frequency must be greater than 1000 milliseconds.");
      this.orbiter.getConnectionMonitor().setAutoReconnectFrequency(
        Controller.DEFAULT_AUTORECONNECT_FREQUENCY,
        Controller.DEFAULT_AUTORECONNECT_FREQUENCY,
        this.systemSettings.autoReconnectDelayFirstAttempt);
    }
  };

  applicationConfigError (e) {
    this.doBootFail("Boot failed at task [LOAD_APPLICATION_CONFIG]. File [" + this.applicationConfigURL + "] failed to load.");
  };

//==============================================================================
// QUERY PARAMS
//==============================================================================
  /**
   * Retrieves and processes the query string parameters from this controller's URL.
   *
   * @returns Boolean If the required params are present, true; otherwise, false.
   */
  processQueryParams () {
    let urlParams = new URLSearchParams(window.location.search);
    if (notNil(urlParams.get('role'))) {
      this.role = urlParams.get('role');
    }
    if (notNil(urlParams.get('position'))) {
      this.position = urlParams.get('position');
    }
    if (notNil(urlParams.get('source_id'))) {
      this.source_id = urlParams.get('source_id');
    }
    if (notNil(urlParams.get('password'))) {
      this.invitation_password = urlParams.get('password');
    }
    if (notNil(urlParams.get('studio_token'))) {
      this.studio_token = urlParams.get('studio_token');
    }
    if (notNil(urlParams.get('env'))) {
      Controller.globals.dataStore.environment = urlParams.get('env');
    } else {
      Controller.globals.dataStore.environment = 'live';
    }
    if (notNil(urlParams.get('m'))) {
      Controller.globals.dataStore.megaphoneToken = urlParams.get('m');
    }

    return notNil(Controller.globals.dataStore.megaphoneToken);
  }

//==============================================================================
// CONTROLLER CONNECT
//==============================================================================
  connect (e) {
    this.orbiter.connect();
  }

//==============================================================================
// CONTROLLER ORBITER EVENT LISTENERS
//==============================================================================
  beginConnectListener (e) {
    this.dispatchControllerBeginConnect();
  };

  connectFailureListener (e) {
    this.dispatchControllerConnectFailure();
    this.channelBridge.dispatchConnectFailed();
  };

  disconnectListener (e) {
    this.dispatchControllerDisconnect();
  };

  readyListener (e) {
    this.orbiter.getMessageManager().addMessageListener(net.user1.orbiter.UPC.JOINED_ROOM, this.joinedRoomListener, this);
    this.orbiter.getMessageManager().addMessageListener(net.user1.orbiter.UPC.OBSERVED_ROOM, this.observedRoomListener, this);
    this.orbiter.getMessageManager().addMessageListener(net.user1.orbiter.UPC.LEFT_ROOM, this.leftRoomListener, this);
    this.orbiter.getMessageManager().addMessageListener(net.user1.orbiter.UPC.CLIENT_ATTR_UPDATE, this.clientAttributeUpdateListener, this);
    this.orbiter.getMessageManager().addMessageListener(net.user1.orbiter.UPC.LOGGED_IN, this.loggedInListener, this);
    this.orbiter.getMessageManager().addMessageListener(net.user1.orbiter.UPC.SESSION_TERMINATED, this.sessionTerminatedListener, this);
    this.orbiter.getMessageManager().addMessageListener(megaphone.ClientMessages.CONTROLLER_READY, this.controllerReadyListener, this);
    this.orbiter.getMessageManager().addMessageListener(megaphone.ClientMessages.APPLICATION_ERROR, this.applicationErrorListener, this);
    this.dispatchControllerSetup();
    this.addCommandListener(ClientMessages.DATA_SETTINGS, this.dataSettingsListener);
    this.sendConnectController();
  };

  closeListener (e) {
    this.ready = false;
    this.numClosesSinceLastReady++;

    if (this.systemSettings.autoReconnectAttemptLimit != -1 &&
      this.numClosesSinceLastReady >= this.systemSettings.autoReconnectAttemptLimit+1) {
      this.getLog().info("[MEGAPHONE_CONTROLLER] Reconnection attempt limit reached. Disabling automatic reconnection.")
      this.orbiter.getConnectionMonitor().setAutoReconnectFrequency(-1);
    }
    this.dispatchControllerQuit();
    this.channelBridge.dispatchQuit();
  };

  joinedRoomListener (roomID) {
    if (roomID.endsWith('channel')) {
      this.getLog().info(`[CONTROLLER] Joined channel room: ${roomID}`);
      this.setChannelRoom(roomID, this.orbiter.self(), this.getLog());
    } else {
      this.roomID = roomID;
    }
  };

  observedRoomListener (roomID) {
    if (roomID.endsWith('channel')) {
      this.getLog().info(`[CONTROLLER] Observed channel room: ${roomID}`);
      this.setChannelRoom(roomID, this.orbiter.self(), this.getLog());
    } else {
      this.roomID = roomID;
    }
  }

  setChannelRoom (roomID, self, log) {
    this.channelRoom = this.orbiter.getRoomManager().getRoom(roomID);

    // Register for channel room events
    this.channelRoom.addEventListener(net.user1.orbiter.RoomEvent.ADD_OCCUPANT, this.addChannelOccupant, this);
    this.channelRoom.addEventListener(net.user1.orbiter.RoomEvent.REMOVE_OCCUPANT, this.removeChannelOccupant, this);
    this.channelRoom.addEventListener(net.user1.orbiter.RoomEvent.UPDATE_CLIENT_ATTRIBUTE, this.updateChannelClientAttributeListener, this);
    this.channelRoom.addEventListener(net.user1.orbiter.AttributeEvent.UPDATE, this.updateChannelRoomAttributeListener, this);

    // Provide dependencies to ChanneBridge
    this.channelBridge.setRoom(this.channelRoom);
    this.channelBridge.setSelfClient(self);
    this.channelBridge.setLog(log);

    // Send channel occupant list and channel attributes to ChanneBridge listeners
    this.channelBridge.setChannelOccupants(this.channelRoom.getOccupants());
    let channelAttrs = this.channelRoom.getAttributes();
    for (let attrName in channelAttrs) {
      this.channelBridge.setChannelAttribute(attrName, channelAttrs[attrName], null);
    }
  }

  leftRoomListener (roomID) {
    if (this.roomID.endsWith('channel')) {
      this.getLog().info(`[CONTROLLER] Left channel room: ${roomID}`);
    } else {
      this.leftRoomCount++;
      this.getLog().info("Application room left. Disconnecting.");
      this.dispatchApplicationError("[CLOSE]", [{summary: "Application closed.", description: "The server-side application is no longer running."}]);
      this.orbiter.disconnect();
    }
  };

  /**
   * Executed when a low level CLIENT_ATTR_UPDATE UPC is received.
   */
  clientAttributeUpdateListener (roomID,
                                                                           clientID,
                                                                           userID,
                                                                           attrName,
                                                                           attrVal,
                                                                           attrOptions) {
    switch (attrName) {
      case megaphone.ClientAttributes.caller_label:
        this.callerLabel = attrVal;
        break;

      case megaphone.ClientAttributes.controller_number:
        this.controllerNumber = attrVal;
        break;

    }
  };

//==============================================================================
// CONTROLLER UNION MESSAGE EVENT LISTENERS
//==============================================================================
  loggedInListener (clientID, userID) {
    this.setControllerKey(userID);
    // Don't save the controller key to local storage for StreamClick producers, otherwise other clients
    // in the same browser will read the key and cause the producer to be disconnected if they join.
    if (this.role !== 'producer') {
      if (hasLocalStorage()) {
        // Local storage is available on the current page, so store controller key and number directly
        localStorage.setItem(Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_KEY, this.getControllerKey());
        localStorage.setItem(Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_NUMBER, this.getControllerNumber());
      } else {
        if (isIframed()) {
          // Controller is embedded, and local storage is not available on the current page, so pass
          // controller key and number to parent page for storage (otherwise the
          // attempt to store the value in localStorage would be considered a third-party cookie, which
          // would be blocked by default in some browsers)
          this.embedBridge.setControllerKey(this.getControllerKey());
          this.embedBridge.set(Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_NUMBER, this.getControllerNumber());
        }
      }
    }
  };

  controllerReadyListener () {
    this.readyCount++;
    this.ready = true;
    this.applyAutoReconnectSettings();
    this.numClosesSinceLastReady = 0;
    this.addCommandListener(ClientMessages.DATA_GLOBAL_INTERVAL, this.dataGlobalIntervalListener);
    this.dispatchControllerReady();
    this.channelBridge.dispatchReady();
  };

  sessionTerminatedListener () {
    this.getLog().info("[MegaphoneController] Session terminated by server.");
    this.orbiter.getConnectionMonitor().setAutoReconnectFrequency(-1);
    this.dispatchSessionTerminated();
  };

  applicationErrorListener (
    fromClientID,
    errorCode) {
    this.appErrorCount++;
    errorCode = errorCode == "" ? "[NONE]" : errorCode;
    var errorString = "[MEGAPHONE_CONTROLLER] Server reports application error."
      + " Error code: " + errorCode + ". Error reports follow.\n";
    var errors = [];
    var error;

    for (var i = 2, j = 1; i < arguments.length; i+=2, j++) {
      error = {};
      error.summary     = arguments[i];
      error.description = arguments[i+1];
      errors.push(error);
      errorString += "        Error " + j + " Summary: " + error.summary + (i == arguments.length-2 ? "" : "\n");
      if (error.description != "") {
        errorString += "        Error " + j + " Description: " + error.description + (i == arguments.length-2 ? "" : "\n");
      }
    }

    this.getLog().fatal(errorString);
    this.dispatchApplicationError(errorCode, errors);
    this.orbiter.disconnect();
  }

//==============================================================================
// CONTROLLER MODULE-MESSAGE EVENT LISTENERS
//==============================================================================
  connectControllerFailedListener (fromClient, statusCode, statusMessage) {
    this.channelBridge.dispatchConnectFailed(statusCode, statusMessage);
  }

//==============================================================================
// CONTROLLER MESSAGE LISTENER WRAPPER
//==============================================================================
  addCommandListener(commandName, commandListener) {
    this.orbiter.getMessageManager().addMessageListener(commandName, commandListener, this);
  }

  removeCommandListener(commandName, commandListener) {
    this.orbiter.getMessageManager().removeMessageListener(commandName, commandListener);
  }

//==============================================================================
// CONTROLLER MODULE-MESSAGE SENDING
//==============================================================================
  sendCommand (commandName, commandArguments) {
    var sendupcArgs = [net.user1.orbiter.UPC.SEND_ROOMMODULE_MESSAGE, this.roomID, commandName];

    for (var arg in commandArguments) {
      sendupcArgs.push(arg + "|" + commandArguments[arg]);
    }

    this.orbiter.getMessageManager().sendUPC.apply(this.orbiter.getMessageManager(), sendupcArgs);
  };

  sendMessageToScreen (messageName, messageArguments) {
    var sendupcArgs = [net.user1.orbiter.UPC.SEND_ROOMMODULE_MESSAGE, this.roomID,
      megaphone.RoomModuleMessages.SEND_COMMAND_TO_SCREEN, "commandName|"+messageName];

    for (var i = 0; i < messageArguments.length; i++) {
      sendupcArgs.push("arg" + (i+1) + "|" + messageArguments[i]);
    }

    this.orbiter.getMessageManager().sendUPC.apply(this.orbiter.getMessageManager(), sendupcArgs);
  };

  sendConnectController () {
    // NOTE: If the controller is a producer (inside Megaphone Studio), then don't send a controller
    // key, otherwise MRTS will not allow this client to connect in the same browser multiple times
    // as a producer (i.e., in other tabs and windows).
    this.orbiter.getMessageManager().sendUPC(net.user1.orbiter.UPC.SEND_SERVERMODULE_MESSAGE,
      megaphone.ServerModules.MEGAPHONE_SERVICES,
      megaphone.ServerModuleMessages.CONNECT_CONTROLLER,
      `role|${this.role}`,
      `position|${this.position}`,
      `source_id|${this.source_id}`,
      `password|${this.invitation_password}`,
      `studio_token|${this.studio_token}`,
      "deployment_key|"+Controller.globals.dataStore.deploymentKey,
      "instance_shortcode|"+this.getInstanceShortcode(),
      "controller[controller_key]|"+`${this.role === 'producer' || !Controller.globals.dataStore.cookiesRequired ? '' : this.getControllerKey()}`,
      "controller[real]|"+this.real,
      "controller_session[controller_type]|"+this.controllerType,
      "controller_session[user_agent]|"+this.userAgent,
      "controller_session[megaphone_sdk_version]|controllerio",
      "controller_session[controller_label]|"+this.userDefinedLabel);
  };

  sendPress (keyCode) {
    this.sendCommand(megaphone.RoomModuleMessages.PRESS, {keyCode:keyCode});
  }

//==============================================================================
// CONTROLLER MODULE-MESSAGE LISTENING
//==============================================================================
  dataGlobalIntervalListener (fromClient, toRoom, numUsers) {
    this.channelBridge.setNumSpectators(parseInt(numUsers));
  }

  dataSettingsListener (fromClient, cco) {
    let messageData = {};
    messageData.value = cco;
    this.channelBridge.send(PostMessages.DATA_SETTINGS, messageData);
  }

//==============================================================================
// CONTROLLER LABEL API
//==============================================================================
  setUserDefinedLabel (value) {
    this.userDefinedLabel = value;
  }

  getLabel () {
    return this.callerLabel;
  }

//==============================================================================
// CONTROLLER INSTANCE SHORTCODE API
//==============================================================================
  getInstanceShortcode () {
    return this.instanceShortcode;
  }

  setInstanceShortcode (value) {
    this.instanceShortcode = value;
  }

//==============================================================================
// STREAMCLICK CHANNEL MANAGEMENT
//==============================================================================

  addChannelOccupant (e) {
    this.getLog().info(`[CONTROLLER] Occupant joined channel. ID: ${e.getClient().getClientID()}, NAME: ${e.getClient().getAttribute('name', this.channelRoom.getRoomID())}`);
    this.channelBridge.addChannelOccupant(e.getClient());
  }

  removeChannelOccupant (e) {
    this.getLog().info(`[CONTROLLER] Occupant left channel. ID: ${e.getClient().getClientID()}, NAME: ${e.getClient().getAttribute('name', this.channelRoom.getRoomID())}`);
    this.channelBridge.removeChannelOccupant(e.getClient());
  }

  /**
   * Executed when one of the channel room attributes changes.
   */
  updateChannelRoomAttributeListener (e) {
    this.channelBridge.setChannelAttribute(e.getChangedAttr().name, e.getChangedAttr().value, e.getChangedAttr().byClient);
  }

  /**
   * Executed when a channel occupant changes a client attribute.
   */
  updateChannelClientAttributeListener (e) {
    console.warn(`CLIENT: ${e.getClient().getClientID()} ${e.getChangedAttr().name} set to ${e.getChangedAttr().value}`);
    this.channelBridge.setOccupantAttribute(e.getChangedAttr().name, e.getChangedAttr().value, e.getClient());
  }


//================================================================================================
// HANDLE POST MESSAGES FROM OTHER WINDOWS
//================================================================================================
  receivePostMessageListener  (e) {
    let message = e.detail.message;
    if (message.type === PostMessage.RESPONSE) {
      // Received a response for a request to the first-party data bubbler...
      switch (message.originMessageID) {
        case this.checkLocalStorageMessageID:
          // This is the response to the local storage check message that was sent by
          // controller during boot, so process it and continue the boot sequence.
          if (message.status === 200) {
            // HAS_LOCAL_STORAGE
            this.handleLocalStorageCheck(true);
          } else {
            if (message.status === 403) {
              this.orbiter.getLog().warn('Local storage is disabled. Enable cookies and reload.');
            } else if (message.status === 404) {
              this.orbiter.getLog().warn('Request for local storage was ignored by a parent window. Embed script is likely missing or needs to be upgraded.');
            }
            this.handleLocalStorageCheck(false);
          }
          break;

        case this.getControllerKeyMessageID:
          // This is the response to the controller-key retrieval message that was sent by
          // controller during boot, so process it and continue the boot sequence.
          if (message.status === 200) {
            this.handleControllerKeyRetrieval(message.fieldValue);
          }
          break;
      }
    }
  }

//==============================================================================
// CONTROLLER GETTERS/SETTERS
//==============================================================================

  getControllerKey () {
    return this.controllerKey;
  }

  setControllerKey (value) {
    this.controllerKey = value;
  }

  getControllerNumber () {
    return this.controllerNumber;
  }

  getDeploymentKey () {
    return this.deploymentKey;
  }

  getAppSettingsRegistry () {
    return this.appSettingsRegistry;
  }

  getReadyCount () {
    return this.readyCount;
  }

  getConnectAttemptCount () {
    return this.orbiter.getConnectionManager().getConnectAttemptCount();
  }

  isReady () {
    return this.ready;
  }

//==============================================================================
// CAPABILITIES
//==============================================================================
  isJavaScriptCompatible () {
    return this.orbiter.getSystem().isJavaScriptCompatible();
  }

//==============================================================================
// CONTROLLER LOGGING
//==============================================================================
  getLog () {
    return this.orbiter.getLog();
  }

  enableConsole() {
    this.orbiter.enableConsole();
  }

//==============================================================================
// CONTROLLER EVENT DISPATCHING
//==============================================================================

  dispatchControllerSetup () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.SETUP));
  }

  dispatchControllerBeginConnect () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.BEGIN_CONNECT));
  }

  dispatchControllerConnectFailure () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.CONNECT_FAILURE));
  }

  dispatchControllerReady () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.READY));
  }

  dispatchControllerDisconnect () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.DISCONNECT));
  }

  dispatchControllerQuit () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.QUIT));
  }

  dispatchSessionTerminated () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.SESSION_TERMINATED));
  }

  dispatchJavaScriptIncompatible () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.JAVASCRIPT_INCOMPATIBLE));
  }

  dispatchNoLocalStorage () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.NO_LOCAL_STORAGE));
  }

  dispatchBootFailed () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.BOOT_FAILED));
  }

  dispatchBootComplete () {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.BOOT_COMPLETE));
  }

  dispatchBootTaskComplete (taskName) {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.BOOT_TASK_COMPLETE, null, null, taskName));
  }

  dispatchApplicationError (errorCode, errors) {
    this.dispatchEvent(new ControllerEvent(ControllerEvent.APPLICATION_ERROR, errorCode, errors));
  }
}

/**
 * This getIfNotEmpty() function is a ControllerIO-specific version of the function by the same name
 * found in mputils (note, however, that this version supports lookup of a leaf-node "key"name only,
 * whereas mputils supports a full property path). Unfortunately, the mputils version cannot be used
 * in ControllerIO because it depends on the "node module" version of lodash, which is not supported
 * in ControllerIO (because, as of Aug 5, 2024, MegaController's version of AngularJS cannot use
 * node modules).
 *
 * @param object A JavaScript object.
 * @param key A property name, such as "height" (must NOT be a full path such as "box.dimensions.height").
 * @param defaultValue The value to return if object.key is empty or not found.
 * @returns {null|*}
 */
Controller.getIfNotEmpty = function (object, key, defaultValue = null) {
  let value;
  if (object.hasOwnProperty(key)) {
    value = object[key];
  }
  return isEmpty(value) ? defaultValue : value;
}

Controller.globals = {};

Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_KEY = 'mp_controller_key';
Controller.LOCALSTORAGE_FIELD_MP_CONTROLLER_NUMBER = 'mp_controller_number';
Controller.DEFAULT_DEBUG_LEVEL = 'DEBUG';
Controller.DEFAULT_HEARTBEAT_FREQUENCY = 25000;
Controller.DEFAULT_AUTORECONNECT_MINMS = 5000;
Controller.DEFAULT_AUTORECONNECT_MAXMS = 10000;
Controller.DEFAULT_AUTORECONNECT_DELAYFIRSTATTEMPT = true;
Controller.DEFAULT_AUTORECONNECT_ATTEMPT_LIMIT = 5;
Controller.DEFAULT_AUTOJOIN_PERCENT = 100;
Controller.DEFAULT_MANUALJOIN_PERCENT = 0;
Controller.DEFAULT_CONNECTION_TIMEOUT = 30000;
Controller.DEFAULT_HTTP_RETRY_DELAY = 3000;
Controller.DEFAULT_CONNECTION_TYPES = [net.user1.orbiter.ConnectionType.SECURE_WEBSOCKET, net.user1.orbiter.ConnectionType.SECURE_HTTP];
