import {PostMessageManager} from '../mputils/PostMessageManager.js';
import {notNil} from '../mputils/utils.js';
import {PostMessageEvent} from '../mputils/PostMessageEvent.js';
import {PostMessages} from './PostMessages.js';

/**
 * Transmits StreamClick channel data between the current window and parent or child windows,
 * including the occupant list and channel attributes.
 */
export class ChannelBridge {
  constructor () {
    this.postMessageManager = new PostMessageManager();
    this.postMessageManager.on(PostMessageEvent.RECEIVE_MESSAGE, this.receiveMessageListener.bind(this));
  }

  //================================================================================================
  // DEPENDENCIES
  //================================================================================================
  setSelfClient (client) {
    this.selfClient = client;
  }

  setRoom (room) {
    this.room = room;
  }

  setLog (log) {
    this.log = log;
  }

  /**
   * Specifies the window or windows to which all post messages will be sent. If not set, messages
   * are sent to the parent window. The targetWindow can be set repeatedly to direct messages to
   * multiple different windows in succession. For example:
   *
   * let channelBridge = new ChannelBridge();
   * channelBridge.setTargetWindows(windowA);
   * channelBridge.dispatchQuit();  // Sends message to windowA
   * channelBridge.setTargetWindows(windowB);
   * channelBridge.dispatchQuit();  // Sends message to windowB
   *
   * @param targetWindows A single window or an array of windows.
   */
  setTargetWindows (targetWindows) {
    if (!Array.isArray(targetWindows)) {
      targetWindows = [targetWindows];
    }
    this.targetWindows = targetWindows;
  }

  //================================================================================================
  // MESSAGES TO TARGET WINDOW
  //================================================================================================
  dispatchConnectFailed (statusCode, statusMessage) {
    let messageData = {};
    messageData.statusCode = statusCode;
    messageData.statusMessage = statusMessage;

    this.send(PostMessages.CONNECT_FAILED, messageData);
  }

  dispatchReady () {
    this.send(PostMessages.READY);
  }

  dispatchQuit () {
    this.send(PostMessages.QUIT);
  }

  /**
   * Send the target window the entire occupant list for the channel room. Used to establish the room's
   * intial state when the current client joins.
   */
  setChannelOccupants (occupants) {
    let occupantsData = [];

    if (notNil(occupants)) {
      occupants.forEach(occupant => {
        let attributes = occupant.getAttributesByScope(this.room.getRoomID());
        if (occupant.isSelf()) {
          delete attributes['_UL'];
          attributes.self = true;
        } else {
          attributes.self = false;
        }
        this.objValuesToJSON(attributes)
        // Add Union client ID to object sent to target window. The client_id is intentionally added
        // AFTER the values are parsed as JSON, otherwise, the string id "123" would be converted
        // to the int 123 by the JSON parser, which is undesirable. IDs should always be strings.
        attributes.client_id = occupant.getClientID();
        occupantsData.push(attributes);
      });
    }
    this.send(PostMessages.OCCUPANTS, occupantsData);
  }

  /**
   * Notify the target window that a client joined the channel room.
   */
  addChannelOccupant (occupant) {
    let attributes = this.objValuesToJSON(occupant.getAttributesByScope(this.room.getRoomID()));
    attributes.self = false;
    attributes.client_id = occupant.getClientID();
    this.send(PostMessages.OCCUPANT_ADDED, attributes);
  }

  /**
   * Notify the target window that a client left the channel room.
   */
  removeChannelOccupant (occupant) {
    if (notNil(occupant)) {
      this.send(PostMessages.OCCUPANT_REMOVED, occupant.getClientID());
    }
  }

  /**
   * Notify the target window that a one of the channel room's attributes changed (e.g., the "switcher").
   */
  setChannelAttribute (name, value, byClient) {
    let messageData = {};
    messageData.byClient = notNil(byClient) ? {clientID: byClient.getClientID(), source_id: byClient.getAttribute('source_id', this.room.getRoomID())} : null;
    messageData.name = name;
    messageData.value = this.toStringOrJSON(value);
    this.send(PostMessages.ROOM_ATTRIBUTE_UPDATED, messageData);
  }

  /**
   * Notify the target window that a client attribute has changed on one of the occupants in the
   * channel room.
   */
  setOccupantAttribute (name, value, client) {
    let messageData = {};
    messageData.client = {clientID: client.getClientID(), source_id: client.getAttribute('source_id', this.room.getRoomID())};
    messageData.name = name;
    messageData.value = this.toStringOrJSON(value);
    this.send(PostMessages.OCCUPANT_ATTRIBUTE_UPDATED, messageData);
  }

  setNumSpectators (value) {
    let messageData = {};
    messageData.value = this.toStringOrJSON(value);
    this.send(PostMessages.NUM_SPECTATORS_UPDATED, messageData);
  }

  /**
   * Converts the values of obj from strings to JSON.
   *
   * @param obj
   */
  objValuesToJSON (obj) {
    // Convert property values to JSON. (Use for-in for backwards compatibility.)
    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        obj[p] = this.toStringOrJSON(obj[p]);
      }
    }
    return obj;
  }

  /**
   * If value is a string, returns value, otherwise returns value parsed as JSON.
   */
  toStringOrJSON (value) {
    try {
      // If no error is thrown by parse(), the property value is JSON
      value = JSON.parse(value);
      return value;
    } catch (e) {
      // Attribute value is just a string
      return value;
    }
  }

  /**
   * Sends a message to the target window.
   */
  send (messageName, data) {
    let message = {
      message: messageName,
      data: data
    };

    if (notNil(this.targetWindows)) {
      // Send to all specified target windows
      this.targetWindows.forEach(targetWindow => {
        if (notNil(targetWindow)) {
          this.postMessageManager.send(message, null, targetWindow);
        }
      });
    } else {
      // No target windows, so send to parent window
      this.postMessageManager.send(message);
    }
  }

  //================================================================================================
  // MESSAGES FROM OTHER WINDOWS
  //================================================================================================
  receiveMessageListener  (e) {
    let message = e.detail.message;
    let messageName = message.message;
    let messageData = message.data;

    if (messageName === PostMessages.SET_OWN_ATTRIBUTE) {
      // SET_OWN_ATTRIBUTE
      if (notNil(this.selfClient)) {
        this.selfClient.setAttribute(messageData.attrName, JSON.stringify(messageData.attrValue), this.room.getRoomID());
      }
    } else if (messageName === PostMessages.SET_OCCUPANT_ATTRIBUTE) {
      // SET_OCCUPANT_ATTRIBUTE
      if (notNil(this.room)) {
        let occupant = this.room.getClient(messageData.client_id);
        if (notNil(occupant)) {
          occupant.setAttribute(messageData.attrName, messageData.attrValue, this.room.getRoomID());
        } else {
          this.log.warn(`[CHANNEL_BRIDGE] Attribute assignment attempted on nonexistent occupant: [${messageData.client_id}]`);
        }
      }
    } else if (messageName === PostMessages.SET_ROOM_ATTRIBUTE) {
      // SET_ROOM_ATTRIBUTE
      if (notNil(this.room)) {
        this.room.setAttribute(messageData.attrName, JSON.stringify(messageData.attrValue));
      }
    }
  }
}

