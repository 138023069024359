import {ServerModules} from './ServerModules.js';
import {ServerModuleMessages} from './ServerModuleMessages.js';
import {RoomModuleMessages} from './RoomModuleMessages.js';
import {ClientMessages} from './ClientMessages.js';
import {ClientAttributes} from './ClientAttributes.js';
import {CoreBootTasks} from './CoreBootTasks.js';
import {utils} from './utils.js';
import {locale} from './Locale.js';
import {Controller} from './Controller.js';
import {ControllerEvent} from './ControllerEvent.js';
import {FirstPartyDataMessageBubbler} from './captivelibs/mputils/postmessage/FirstPartyDataMessageBubbler.js';
import {FirstPartyStorageService} from './captivelibs/mputils/postmessage/FirstPartyStorageService.js';
import {FirstPartyStorageEvent} from './captivelibs/mputils/postmessage/FirstPartyStorageEvent.js';

export class ControllerIO {
   constructor () {
     window.megaphone = {};
     window.megaphone.Controller = Controller;
     window.megaphone.ControllerEvent = ControllerEvent;
     window.megaphone.locale = locale;
     window.megaphone.ServerModules = ServerModules;
     window.megaphone.ServerModuleMessages = ServerModuleMessages;
     window.megaphone.RoomModuleMessages = RoomModuleMessages;
     window.megaphone.ClientMessages = ClientMessages;
     window.megaphone.ClientAttributes = ClientAttributes;
     window.megaphone.CoreBootTasks = CoreBootTasks;
     window.megaphone.utils = utils;
     window.megaphone.firstPartyDataMessageBubbler = new FirstPartyDataMessageBubbler();
     window.megaphone.firstPartyStorageService = new FirstPartyStorageService();
     window.megaphone.firstPartyStorageEvent = FirstPartyStorageEvent;
     window.megaphone.CONTROLLER_LOADED = true;
   }
}
new ControllerIO();

