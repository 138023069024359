export class Event {
  constructor (type) {
    if (type !== undefined) {
      this.type = type;
    } else {
      throw new Error("Event creation failed. No type specified. Event: "+this);
    }
    this.target = null;
  }

  toString () {
    return "[object Event]";
  }
}
