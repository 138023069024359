import {Task} from '../mputils/Task.js';
import {MRTSEndpoint} from './MRTSEndpoint.js';
import {LoadError} from '../mputils/LoadError.js';
import {isEmpty, replaceAll, isNil} from '../mputils/utils.js';
import {log, fatal} from '../mputils/logfunctions.js';
import {COMPLETE, FAIL} from '../mputils/constants.js';
import {LoadJSONTask} from '../mputils/LoadJSONTask.js';
import {CLIENT_TYPE_SCREEN} from './constants.js';

/**
 * Retrieves the MRTS endpoint configured for MegaScreen for the current deployment ID.
 */
export class GetMRTSRouteTask extends Task {
  /**
   * @param dataStore The dataStore in which to assign the MRTS route information.
   * @param clientType One of the official MRTS client types CLIENT_TYPE_SCREEN, CLIENT_TYPE_CONTROLLER, etc.
   * @param externalMRTSRoute A JSON object containing the complete MRTS route as would be returned
   *                          by Nexus. Use case: Clients such as MegaController may wish to load
   *                          the MRTS route from Nexus before deciding whether to connect to MRTS.
   *                          Allowing such clients to supply the MRTS route alleviates the need to
   *                          make a redundant Nexus request in GetMRTSRouteTask.
   */
  constructor (dataStore, clientType, externalMRTSRoute) {
    super();
    this.name = "GET_MRTS_ROUTE";
    this.dataStore = dataStore;
    this.clientType = isEmpty(clientType) ? CLIENT_TYPE_SCREEN : clientType;
    this.externalMRTSRoute = externalMRTSRoute;
  }

  start () {
    super.start();

    if (isNil(this.externalMRTSRoute)) {
      let megaphoneToken = this.dataStore.megaphoneToken;
      if (isEmpty(megaphoneToken)) {
        fatal('No Megaphone Token found.');
        this.fail(new Error('Missing Megaphone Token'));
        return;
      }

      // Load the MRTS route from Nexus
      this.loadMRTSRoute(megaphoneToken);
    } else {
      // An externalMRTSRoute was supplied (e.g., by MegaController), so skip contacting Nexus
      // and move directly to the route-processing stage.
      this.onMRTSRouteLoadSuccess(this.externalMRTSRoute)
    }
  }

  onMRTSRouteLoadSuccess (route) {
    try {
      // Controller cannot use npm modules, so use try/catch instead of lodash 'get' to avoid the need
      // to import lodash in this class.
      // TODO: When Controller is upgraded to ES6, replace this try/catch block with lodash 'get'.
      this.dataStore.mrtsEndpoint = new MRTSEndpoint(route.mrts.server, route.mrts.ports);
      this.dataStore.deploymentID = route.deployment.id;
      this.dataStore.deploymentKey = route.deployment.key;
      this.dataStore.deploymentPassword = route.deployment.password;

      if (route.account != null && route.account.key != null) {
        this.dataStore.accountKey = route.account.key;
      }

      if (route.controller != null && route.controller.cookies_required != null) {
        this.dataStore.cookiesRequired = route.controller.cookies_required;
      }

      this.dataStore.nexusResponsePayload = route;
    } catch (e) {
      log('Loaded MRTS route: ', route)
      this.fail(new Error('Error parsing MRTS route.'));
    }

    log(`MRTS host: [${this.dataStore.mrtsEndpoint.host}], ports: [${this.dataStore.mrtsEndpoint.ports}]`);
    log(`Deployment ID: [${this.dataStore.deploymentID}], deployment key: [${this.dataStore.deploymentKey}]`);
    this.done();
  }

  onMRTSRouteLoadFail (error, megaphoneToken) {
    // MRTS endpoint failed to load
    if (error instanceof LoadError) {
      if (error.HTTPStatus === 404) {
        fatal(`Failed to load MRTS route from Nexus. The Megaphone token [${megaphoneToken}] is unknown.`
          + ` The token might have expired or might have been entered incorrectly. Please check the token and try again. Nexus URI: ${error.uri}`);
      } else {
        fatal(`Failed to load MRTS route from Nexus. Megaphone token: [${megaphoneToken}]. HTTP status ${error.HTTPStatus}. URI: ${error.uri}`);
      }
    } else {
      fatal(`Failed to load MRTS route from Nexus. Megaphone token: [${megaphoneToken}]. Either the Internet connection is`
        + `unavailable or an error occurred retrieving or parsing the response from Nexus.`);
    }
    this.fail(new Error('MRTS endpoint load failed'));
  }

  /**
   * Retrieves the MRTS endpoint and deployment information that this MRTS client should use when
   * connecting to MRTS.
   */
  loadMRTSRoute (megaphoneToken) {
    // Build the Nexus URL to request
    let env = this.dataStore.environment;
    let nexusURL = replaceAll(GetMRTSRouteTask.NEXUS_URL, {
      '$env': (isEmpty(env) || (env === 'live')) ? '' : `-${env}`,
      '$clientType': this.clientType
    });

    let loadJSONTask = new LoadJSONTask(nexusURL, {nocache: true, params: {m: megaphoneToken}});
    loadJSONTask.on(COMPLETE, () => {
      this.onMRTSRouteLoadSuccess(loadJSONTask.json);
    });
    loadJSONTask.on(FAIL, (e) => {
      this.onMRTSRouteLoadFail(e.detail.error, megaphoneToken)
    });
    loadJSONTask.start();
  }
}

GetMRTSRouteTask.NEXUS_URL = `https://nexus$env.megaphonetv.com/mrts/$clientType/`;
